import {db} from "@/firebaseInit";

export async function fetchRequestsByUsers(type) {
  const allRequests = await db.collection("requests")
    .where("type", "==", type)
    .where("status", "!=", "DELETED")
    .get();

  const queries = allRequests.docs.map(doc => db
    .collection("login_history")
    .doc(doc.data().added_by)
    .get()
  );

  const allLogins = await Promise.all(queries);

  return allRequests.docs.map((doc, index) => {
    const user = allLogins[index];
    return {
      ...doc.data(),
      id: doc.id,
      email: user.data().email,
      name: user.data().name,
    }
  });
}
