import { db, storage } from "@/firebaseInit";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
import {CLIENTS_STATUSES_DICT, PROSPECTS_STATUSES_DICT} from "@/utils/statusesConstants";
import {processSingleEntityResponse} from "@/utils/processSingleEntityResponse";

const prospects = RepositoryFactory.get("prospects");
const clients = RepositoryFactory.get("clients");

const state = {
  usersData: [],
  brandFilter: [],
  singleBrandDetail: [],
  singleUsersPolicies: [],
  selectedBrandFilter: null,
  loader: false,
  globalLoader: false,
  newProspectId: "",
  error: "",
  successMessage: "",
  clients: [],
  exists: undefined,
  archive: null,
};
const getters = {
  getArchivedBrands: state => state.archive,
  clientExists: state => state.exists,
  successMessage: state => state.successMessage,
  error: state => state.error,
  loader: (state) => state.loader,
  usersData: (state) => state.usersData,
  clients: (state) => state.clients,
  brandFilterData: (state) => state.brandFilter,
  singleBrandDetail: (state) => state.singleBrandDetail,
  getSingleUsersPolicies: (state) => state.singleUsersPolicies,
  selectedBrandFilter: (state) => state.selectedBrandFilter,
  newProspectId: (state) => state.newProspectId,
  globalLoader: (state) => state.globalLoader,
};
const actions = {
  async fetchData({ commit }) {
    db.collection("login_history").onSnapshot((querySnapshot) => {
      let temp = [];
      querySnapshot.forEach((doc) => {
        temp.push({ ...doc.data(), id: doc.id });
      });
      commit("setusersData", temp);
      commit("setLoader", false);
    });
  },
  async checkClientExists({commit}, payload) {
    commit('setLoader', true);

    try {
      const ref = await db.collection("clients")
        .where("brand_filter_id", "==", payload)
        .get();

      if (ref.docs.length) {
        commit('setExists', true);
        return;
      }

      commit('setExists', false);
    } catch (e) {
      commit('setError', e.code ? e?.message : "Something wrong, please try to use this button later.");
      commit('setExists', undefined);
    } finally {
      commit('setLoader', false);
    }
  },
  async getSingleClientDetails({commit, dispatch, getters}, payload) {
    commit('setGlobalLoader', true);

    if (Object.keys(getters.singleBrandDetail).length) {
      commit('singleBrandDetail', {});
    }

    try {
      const response = await clients.getSingleClient(payload);
      const result = await processSingleEntityResponse(response, storage);
      commit("singleBrandDetail", result);
      commit("setSelectedBrandFilter", result);
      await dispatch('getAssignedPolicies', payload);
    } catch (e) {
      commit('setError', e.code ? e?.message : "Something wrong, please try to load the page later.")
    } finally {
      commit('setGlobalLoader', false);
    }
  },
  async fetchClients({commit}) {
    commit('setLoader', true);

    try {
      const response = await clients.getClients();
      commit("setClients", response.data);
    } catch (e) {
      commit('setError', e.code ? e?.message : "Something wrong, please try to load the page later.");
    } finally {
      commit('setLoader', false);
    }
  },
  async fetchArchivedBrandFilters({ commit }) {
    commit('setLoader', true);

    try {
      const response = await prospects.getArchived();
      commit("setArchivedList", response.data);
    } catch (e) {
      commit('setError', e.code ? e?.message : "Something wrong, please try to load the page later.");
    } finally {
      commit('setLoader', false);
    }
  },
  async fetchBrandFilter({ commit }) {
    commit('setLoader', true);

    try {
      const response = await prospects.getProspects();
      commit("setBrandFilter", response.data);
    } catch (e) {
      commit('setError', e.code ? e?.message : "Something wrong, please try to load the page later.");
    } finally {
      commit('setLoader', false);
    }
  },
  async setSelectedBrandFilter({ commit }, payload) {
    console.log(payload);
    commit("setSelectedBrandFilter", payload);
  },
  async getAssignedPolicies({ commit }, payload) {
    const assignedPolicies = await db.collection("assigned_policies")
      .where("id", "==", payload)
      .get();

    const userPolicies = assignedPolicies.docs.map(doc => ({ ...doc.data(), docId: doc.id }));

    commit("setSingleUsersPolicies", userPolicies);
  },
  async getSingleBrandDetail({ commit, dispatch, getters }, payload) {
    commit('setGlobalLoader', true);

    if (Object.keys(getters.singleBrandDetail).length) {
      commit('singleBrandDetail', {});
    }

    try {
      const response = await prospects.getSingleProspect(payload);
      const result = await processSingleEntityResponse(response, storage);

      commit("singleBrandDetail", result);
      await dispatch('getAssignedPolicies', payload);
    } catch (e) {
      commit('setError', e.code ? e?.message : "Something wrong, please try to load the page later.")
    } finally {
      commit('setGlobalLoader', false);
    }
  },
  // Newly Added -- Client
  async updateClient({ commit }, { id, survey_id, data, deletedFacilityIds, onSuccessRequestEnd }) {
    commit('setLoader', true);
    const defaultErrorMessage = 'Something wrong, please try to update the client later';

    let {
      policies: { selectedFile, selected, files },
    } = data;
    let prospectivePoliciesPayload = {
      selected,
      files: files.map(f => `${data.email}_${f.name}`)
    };

    if (selectedFile && typeof selectedFile !== "string") {
      try {
        let parts = selectedFile.name.split(".");
        let ext = parts[parts.length - 1];
        let filename = `${data.email}_${parts[0]}_${files?.length || 0}.${ext}`;
        let path = `/policies/${filename}`;
        await storage.ref(path).put(selectedFile);

        prospectivePoliciesPayload = {
          ...prospectivePoliciesPayload,
          files: [...prospectivePoliciesPayload.files, filename]
        }
      } catch (err) {
        commit('setLoader', false);
        commit('setError', defaultErrorMessage);
        return;
      }
    }

    try {
      const puDocRef = await db
        .collection("prospective_users")
        .doc(data.added_by)
        .get();

      if (!puDocRef.data()) {
        throw new Error('No User found');
      }

      await puDocRef.ref.update({
        firstName: data.firstName || '',
        lastName: data.lastName || ''
      });
    } catch (err) {
      commit('setLoader', false);
      commit('setError', defaultErrorMessage);
      return;
    }

    try {
      const usersPoliciesRef = await db.collection("prospective_users_policies")
        .where("brandFilterDocKey", "==", id)
        .get();

      if (usersPoliciesRef.docs.length) {
        await usersPoliciesRef.docs[0].ref.update({policies: prospectivePoliciesPayload});
      } else {
        await db.collection("prospective_users_policies")
          .doc()
          .set({
            brandFilterDocKey: id,
            policies: prospectivePoliciesPayload
          });
      }
    } catch (err) {
      commit('setLoader', false);
      commit('setError', defaultErrorMessage);
      return;
    }

    try {
      const brandFilterProps = [
        'brand_name',
        'annual_revenue',
        'sell_online',
        'fulfillment_description',
        'how_many_employees',
        'ownership_structure',
        'ownership_structure_description',
        'how_many_states',
        'state_of_operation',
        'product_category',
        'product_category_description',
        'own_manufacturing',
        'invitation_stage',
        'status'
      ];

      const brandFilterNewDoc = {};
      const surveyNewDoc = {...data};
      for (const prop of brandFilterProps) {
        if (data[prop] !== null && data[prop] !== '' && data[prop] !== undefined) {
          brandFilterNewDoc[prop] = data[prop];
        }
        if (survey_id) {
          delete surveyNewDoc[prop];
        }
      }

      await db.collection("brand_filter")
        .doc(id)
        .update(brandFilterNewDoc);

      if (survey_id) {
        if (data.facilities?.length) {
          const batch = db.batch();
          data.facilities.forEach((doc) => {
            if (doc.id) {
              const facilityDocRef = db.collection("facilities").doc(doc.id);
              delete doc.id;
              batch.set(facilityDocRef, {
                ...doc,
                brand_filter_id: id
              });
            } else {
              const facilityDocRef = db.collection("facilities").doc();
              batch.set(facilityDocRef, {
                ...doc,
                brand_filter_id: id
              });
            }
          });

          if (deletedFacilityIds.length) {
            deletedFacilityIds.forEach(facilityId => {
              const facilityDocRef = db.collection("facilities").doc(facilityId);
              batch.delete(facilityDocRef);
            });
          }

          await batch.commit()
        }

        delete surveyNewDoc.facilities;
        delete surveyNewDoc.policies;
        delete surveyNewDoc.email;
        delete surveyNewDoc.name;
        delete surveyNewDoc.survey;

        await db.collection("surveys")
          .doc(survey_id)
          .update(surveyNewDoc);
      }

      onSuccessRequestEnd();
    } catch (err) {
      commit('setError', defaultErrorMessage);
    } finally {
      commit('setLoader', false);
    }
  },
  // Prospects
  async addProspects({ commit, rootState, dispatch }, { data, onSuccessRequestEnd, isClientCreateFlow }) {
    commit('setLoader', true);
    let isAlready = (
      await db
        .collection("prospective_users")
        .where("email", "==", data.email)
        .get()
    ).size;
    if (isAlready !== 0) {
      commit('setError', `${isClientCreateFlow ? 'Client' : 'Prospect'} with email ${data.email} already exists`);
      commit('setLoader', false);
      return;
    }

    const defaultErrorMessage = `
      Something wrong, please try to create a 
      ${isClientCreateFlow ? 'client' : 'prospect'} 
      later.
    `;

    let brandFilterId = db.collection("brand_filter").doc().id;
    commit("setNewProspectId", brandFilterId);

    let {
      policies: { selectedFile, selected },
    } = data;
    if (selectedFile && typeof selectedFile !== "string" || selected.length) {
      try {
        let filename = '';

        if (selectedFile) {
          let parts = selectedFile.name.split(".");
          let ext = parts[parts.length - 1];
          filename = `${data.email}_${parts[0]}_0.${ext}`;
          let path = `/policies/${filename}`;
          await storage.ref(path).put(selectedFile);
        }

        await db.collection("prospective_users_policies")
          .doc()
          .set({
            brandFilterDocKey: brandFilterId,
            policies: {
              files: filename ? [filename] : [],
              selected
            },
          });
      } catch (err) {
        commit('setError', defaultErrorMessage);
        commit('setLoader', false);
        return;
      }
    }
    try {
      const brandFilterProps = [
        'brand_name',
        'annual_revenue',
        'sell_online',
        'fulfillment_description',
        'how_many_employees',
        'ownership_structure',
        'ownership_structure_description',
        'how_many_states',
        'state_of_operation',
        'product_category',
        'product_category_description',
        'own_manufacturing',
        'invitation_stage',
        'status'
      ];
      let surveyId = db.collection("surveys").doc().id;

      const prospectiveUserRef = db.collection("prospective_users").doc();
      const puId = prospectiveUserRef.id;
      await prospectiveUserRef
        .set({
          email: data.email,
          firstName: data.firstName || '',
          lastName: data.lastName || ''
        });

      const brandFilterNewDoc = {
        added_by: puId,
        survey_id: surveyId
      };

      if (!isClientCreateFlow) {
        brandFilterNewDoc.createdAt = Date.now();
      }

      const surveyNewDoc = {...data};
      for (const prop of brandFilterProps) {
        if (data[prop] !== null && data[prop] !== '' && data[prop] !== undefined) {
          brandFilterNewDoc[prop] = data[prop];
        }
        delete surveyNewDoc[prop];
      }

      if (data.facilities?.length) {
        const batch = db.batch();
        data.facilities.forEach((doc) => {
          const facilityDocRef = db.collection("facilities").doc();
          batch.set(facilityDocRef, {
            ...doc,
            brand_filter_id: brandFilterId
          });
        });
        await batch.commit()
      }

      delete surveyNewDoc.facilities;
      delete surveyNewDoc.policies;
      delete surveyNewDoc.email;
      delete surveyNewDoc.name;

      await db.collection("surveys")
          .doc(surveyId)
          .set(surveyNewDoc);

      await db.collection("brand_filter")
        .doc(brandFilterId)
        .set(brandFilterNewDoc);

      if (isClientCreateFlow) {
        await db.collection("clients")
          .doc()
          .set({
            brand_filter_id: brandFilterId,
            email: data.email,
            status: CLIENTS_STATUSES_DICT.active
          });

        await dispatch('sendInvitation', {id: brandFilterId, email: data.email}, {root: true});
      }

      onSuccessRequestEnd({ id: brandFilterId });
    } catch (err) {
      debugger;
      commit('setError', defaultErrorMessage);
      commit('setLoader', false);
    }
  },
  async addClientRecord(store, {id, email}) {
    try {
      await db.collection("clients")
        .doc()
        .set({
          email,
          brand_filter_id: id,
          status: CLIENTS_STATUSES_DICT.active
        })
    } catch (e) {
      throw e;
    }
  },
  async changeClientStatus({commit, dispatch, getters}, {id}) {
    commit('setLoader', true);
    commit('setError', '');
    commit('setSuccessMessage', '');

    try {
      const clientRef = db.collection("clients")
        .where("brand_filter_id", "==", id);
      const clientResponse = await clientRef.get();

      if (!clientResponse.docs.length) {
        throw new Error('No brand found.');
      }

      const bfRef = db.collection("brand_filter").doc(id);
      const bfResponse = await bfRef.get();

      if (!bfResponse.data()) {
        throw new Error('No brand found.');
      }

      const {status} = clientResponse.docs[0].data();
      const {brand_name} = bfResponse.data();

      const notArchived = status !== PROSPECTS_STATUSES_DICT.archived;
      const successMessage = `Client with name
          ${brand_name}
          ${notArchived ? 'archived' : 'unarchived'}
          successfully.
        `;

      await clientResponse.docs[0].ref.update({
        status: notArchived ?
          CLIENTS_STATUSES_DICT.archived :
          CLIENTS_STATUSES_DICT.active
      });
      commit('setSuccessMessage', successMessage);
    } catch (e) {
      commit('setError', e.code ? e?.message : "Something wrong, please try to use this button later.");
    } finally {
      commit('setLoader', false);
    }
  },
  changeBrandStatus: {
    async handler({ commit, dispatch }, { id, onSuccessMessage, statusToSet, fieldName, email }) {
      commit('setLoader', true);
      commit('setError', '');
      commit('setSuccessMessage', '');

      try {
        const docRef = db.collection("brand_filter").doc(id);
        const response = await docRef.get();

        if (!response.data()) {
          throw new Error('No brand found.');
        }

        await docRef.update({[fieldName]: statusToSet});

        if (fieldName === 'status' && statusToSet === PROSPECTS_STATUSES_DICT.processed && email) {
          await dispatch('addClientRecord', {id, email});
        }

        commit('setSuccessMessage', onSuccessMessage);
      } catch (e) {
        commit('setError', e.code ? e?.message : "Something wrong, please try to use this button later.");
      } finally {
        commit('setLoader', false);
      }
    },
    root: true
  }
};
const mutations = {
  setSuccessMessage(state, payload) {
    state.successMessage = payload;
  },
  setError(state, payload) {
    state.error = payload;
  },
  setClients(state, payload) {
    state.clients = payload;
  },
  setusersData(state, payload) {
    state.usersData = payload;
  },
  setSelectedBrandFilter(state, payload) {
    state.selectedBrandFilter = payload;
  },
  setBrandFilter(state, payload) {
    let date;

    state.brandFilter = payload.map(bf => {
      bf.createdAtStr = null;

      if (bf.createdAt) {
        date = new Date(Number(bf.createdAt));
        bf.createdAtStr = `${date.toLocaleString('en-US', {
          month: 'short',
        })} ${date.getDate()} ${date.getFullYear()}`;
      }

      return bf;
    });
  },
  setArchivedList(state, payload) {
    state.archive = payload;
  },
  singleBrandDetail(state, payload) {
    state.singleBrandDetail = payload;
  },
  setSingleUsersPolicies(state, payload) {
    state.singleUsersPolicies = payload;
  },
  setNewProspectId(state, payload) {
    state.newProspectId = payload;
  },
  setLoader(state, payload) {
    state.loader = payload;
  },
  setGlobalLoader(state, payload) {
    state.globalLoader = payload;
  },
  setExists(state, payload) {
    state.exists = payload;
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
