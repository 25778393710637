import { db } from "@/firebaseInit";
import {fetchRequestsByUsers} from "@/utils/fetchRequestsByUsers";

const state = {
  requestLoader: true,
  coiRequsetData: [],
  increaseRequsetData: [],
  singleIncreaseRequset: [],
  claimData: [],
};
const getters = {
  requestLoader: (state) => state.requestLoader,
  coiRequset: (state) => state.coiRequsetData,
  increaseRequset: (state) => state.increaseRequsetData,
  singleIncreaseRequset: (state) => state.singleIncreaseRequset,
  claim: (state) => state.claimData,
};
const actions = {
  async fetchCoiRequestes({ commit }) {
    commit("setLoader", true);
    try {
      const result = await fetchRequestsByUsers("request_coi");
      commit("setCoiRequest", result);
    } catch (e) {
      console.error(e);
      commit("setCoiRequest", []);
    } finally {
      commit("setLoader", false);
    }
  },
  // Increase Requset Table Data
  async fetchIncreaseRequestes({ commit }) {
    commit("setLoader", true);
    try {
      const result = await fetchRequestsByUsers("request_increase");
      commit("setIncreaseRequest", result);
    } catch (e) {
      console.error(e);
      commit("setIncreaseRequest", []);
    } finally {
      commit("setLoader", false);
    }
  },
  // Increase Requset Single View
  async increaseRequestDetail({ commit }, payload) {
    try {
      let ref = await db
        .collection("requests")
        .doc(payload)
        .get();
      commit("singleIncreaseRequset", { ...ref.data(), id: ref.id });
    } catch (error) {
      console.log(error.message);
    }
  },

  // Claim Table Data
  async fetchClaim({ commit }) {
    commit("setLoader", true);
    try {
      const result = await fetchRequestsByUsers("claim");
      commit("setClaimData", result);
    } catch (e) {
      console.error(e);
      commit("setClaimData", []);
    } finally {
      commit("setLoader", false);
    }
  },
};
const mutations = {
  setLoader(state, payload) {
    state.requestLoader = payload;
  },
  setCoiRequest(state, payload) {
    state.coiRequsetData = payload;
  },
  setIncreaseRequest(state, payload) {
    state.increaseRequsetData = payload;
  },
  setClaimData(state, payload) {
    state.claimData = payload;
  },
  singleIncreaseRequset(state, payload) {
    state.singleIncreaseRequset = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
