import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import moment from "moment";
import "./registerServiceWorker";
import ArgonDashboard from "./plugins/argon-dashboard";
import BootstrapVue from "bootstrap-vue";
import global from "./mixins/global";
Vue.mixin(global);

Vue.config.productionTip = false;
Vue.prototype.moment = moment;
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);

  let user = JSON.parse(localStorage.getItem("user"));
  if (to.name === "login" && user) {
    next("/dashboard");
  } else if (requiresAuth && !user) {
    localStorage.clear();
    next("/");
  } else {
    next();
  }
});

Vue.use(ArgonDashboard);
Vue.use(BootstrapVue);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
