import { RepositoryFactory } from "@/repository/RepositoryFactory";
import { db } from "@/firebaseInit";

let user = RepositoryFactory.get("user");

const state = {
  super_admins: [],
  getLoader: false,
  changeStatusLoader: false,
  signInUsers: "idle",
};
const getters = {
  super_admins: (state) => state.super_admins,
  getAddUserLoader: (state) => state.getLoader,
  getChangeStatusLoader: (state) => state.changeStatusLoader,
  signInUsers: (state) => state.signInUsers,
};
const actions = {
  async getSuper_admin({ commit }, payload) {
    commit("setSuper_admin", "loading");
    try {
      await db.collection("super_admin").onSnapshot((querySnapshot) => {
        commit("setSuper_admin", "loading");
        let temp_super_admin = [];
        querySnapshot.forEach((doc) => {
          temp_super_admin.push({ docId: doc.id, ...doc.data() });
        });
        commit("setSuper_admin", temp_super_admin);
        return {
          success: true,
        };
      });
    } catch (error) {
      console.log(error.message);
    }
  },
  async addAdmin({ commit }, payload) {
    commit("setLoader", true);
    try {
      let result = await user.addUser(payload);
      commit("setLoader", false);
      return {
        success: !Object.keys(result.data).includes("err"),
        message: Object.keys(result.data).includes("err")
          ? result.data.err
          : "",
      };
    } catch (error) {
      commit("setLoader", false);
      alert(error.response.data.error);
      return {
        success: false,
      };
    }
  },
  async editAdmin({ commit }, payload) {
    commit("setLoader", true);
    try {
      await user.editUser(payload);
      commit("setLoader", false);
      return {
        success: true,
      };
    } catch (error) {
      commit("setLoader", false);
      alert(error.response.data.error);
      return {
        success: false,
      };
    }
  },
  async changeStatus({ commit }, payload) {
    commit("setChangeStatusLoader", true);

    try {
      await user.disableUser(payload);
      commit("setChangeStatusLoader", false);
    } catch (error) {
      alert(error.response.data.error);
      commit("setChangeStatusLoader", false);
    }
  },
  async listUsers({ commit }) {
    let { data } = await user.listUsers();
    commit("setSignInUsers", data.data);
  },
};

const mutations = {
  setSuper_admin(state, payload) {
    state.super_admins = payload;
  },
  setLoader(state, payload) {
    state.getLoader = payload;
  },
  setChangeStatusLoader(state, payload) {
    state.changeStatusLoader = payload;
  },
  setSignInUsers(state, payload) {
    state.signInUsers = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
