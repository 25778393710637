<template>
  <div class="card shadow">
    <div class="card-header border-0">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0">
            {{ title }}
          </h3>
        </div>
        <div class="col-3">
          <div>
            <b-form-select v-model="selectedFilter" :options="filter"></b-form-select>
          </div>
        </div>
      </div>
    </div>

    <div v-if="requestLoader" class="text-center">
      <b-spinner
        style="width: 3rem; height: 3rem;"
        label="Large Spinner"
      ></b-spinner>
    </div>
    <div v-else-if="getData.length === 0" class="text-center text-muted">
      <hr />
      <p class="mb-5">
        No request found!
      </p>
    </div>
    <div v-else class="table-responsive">
      <table class="table align-items-center table-flush">
        <thead>
          <tr>
            <th class="sortable" :class="sortField === 'name' ? sortDirection : ''" @click="sortByField('name')">
              Name
            </th>
            <th>Date Covered</th>
            <th>Status</th>
            <th></th>
            <th></th>
          </tr>
        </thead>

        <tbody v-for="(data, index) in getData" :key="index">
          <tr>
            <th>{{ data.name }}</th>
            <td>{{ data.date_coverage }}</td>
            <td>
              <b-badge class="badge-dot mr-4">
                <i
                  class="bgPending"
                  :class="{ bgCompleted: data.status === `CLOSED` }"
                ></i>
                <span
                  class="status statusPending"
                  :class="{ statusCompleted: data.status === `CLOSED` }"
                  >{{ data.status }}</span
                >
              </b-badge>
            </td>
            <td>
              <base-button
                type="warning"
                size="sm"
                @click.prevent="detailedView(data.id)"
                v-b-modal.modal-increaseRequest
                >View</base-button
              >
            </td>
            <td>
              <base-button
                v-if="data.status !== `CLOSED`"
                type="success"
                size="sm"
                @click.prevent="closeRequsest(data)"
                >Close</base-button
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- modal -->
    <b-modal
      id="modal-increaseRequest"
      size="lg"
      centered
      ok-variant="danger"
      title="Increase Request"
      ok-only
      ok-title="Close"
    >
      <div>
        <h4 class="my-3">
          <b>Date coverage is needed: </b>
          {{ singleIncreaseRequset.date_coverage }}
        </h4>
        <h4 class="my-3">
          <b>Coverage increase required for a new agreement: </b>
          {{ singleIncreaseRequset.coverage_increase_required }}
        </h4>
        <h4 class="my-3">
          <b>Coverage Required: </b>
          {{ singleIncreaseRequset.how_much_required }}
        </h4>
        <h4 class="my-3" v-if="singleIncreaseRequset.higher_coverage_document">
          <b>Related Documents: </b>
          <a
            :href="singleIncreaseRequset.higher_coverage_document"
            target="_blank"
          >
            View File</a
          >
        </h4>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { getAlphabeticallySortedData } from "@/utils/arrayUtils";
export default {
  name: "IncreaseRequestTable",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      selectedFilter: "ALL",
      sortField: null,
      sortDirection: null,
      filter: [
        { value: "ALL", text: "All" },
        { value: "PENDING", text: "PENDING" },
        { value: "CLOSED", text: "CLOSED" },
      ],
    };
  },
  methods: {
    ...mapActions([
      "fetchIncreaseRequestes",
      "requestClose",
      "increaseRequestDetail",
    ]),
    closeRequsest(data) {
      this.requestClose(data).then(() => {
        this.fetchIncreaseRequestes();
      });
    },
    detailedView(id) {
      this.increaseRequestDetail(id);
    },
    sortByField(field) {
      this.sortField = field;
      this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
    }
  },
  computed: {
    ...mapGetters([
      "increaseRequset",
      "requestLoader",
      "singleIncreaseRequset",
    ]),
    getData() {
      if (this.selectedFilter === `ALL`) {
        return this.sortedData;
      } else {
        return this.sortedData.filter(
          (request) => request.status === this.selectedFilter
        );
      }
    },
    sortedData() {
      return getAlphabeticallySortedData(this.increaseRequset, this.sortField, this.sortDirection);
    },
  },
  mounted() {
    this.fetchIncreaseRequestes();
  }
};
</script>
<style scoped>
.statusPending {
  color: red !important;
}
.statusCompleted {
  color: #2dce89 !important;
}
.bgPending {
  background: red !important;
}
.bgCompleted {
  background: #2dce89 !important;
}
</style>
