import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import usersData from "./modules/usersData";
import postRequest from "./modules/postRequest";
import userRequsetsData from "./modules/userRequsetsData";
import requsetClose from "./modules/requsetClose";
import super_admin from "./modules/super_admin";
import invitations from "@/store/modules/invitations";
Vue.use(Vuex);
export default new Vuex.Store({
  state: {},
  getters: {},
  actions: {},
  mutations: {},
  modules: {
    auth,
    invitations,
    usersData,
    postRequest,
    userRequsetsData,
    requsetClose,
    super_admin,
  },
});
