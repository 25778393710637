import axios from "axios";
import {getLoggedInFirebaseUser} from "@/utils/getLoggedInFirebaseUser";
const baseDomain = process.env.NODE_ENV === 'production' ?
  process.env.VUE_APP_CLOUD_FUNCTIONS_BASE_URL :
  process.env.VUE_APP_CLOUD_FUNCTIONS_EMULATOR_URL;
const baseURL = `${baseDomain}`;
let axiosObj;
axiosObj = axios.create({
  baseURL,
});
axiosObj.interceptors.request.use(async (config) => {
  const user = await getLoggedInFirebaseUser();
  const token = await user.getIdToken();
  config.headers.authorization = `Bearer ${token}`;
  return config;
});
export default axiosObj;
