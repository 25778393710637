import Vue from "vue";
import Router from "vue-router";
import AuthLayout from "@/layout/AuthLayout.vue";
import Dashboard from "@/views/Dashboard.vue";
import IncreaseRequest from "@/views/IncreaseRequest.vue";
import CoiRequests from "@/views/CoiRequests.vue";
import StartClaim from "@/views/StartClaim.vue";
import WriteProspects from "@/views/WriteProspects.vue";
import WriteClients from "@/views/WriteClients.vue";
import BrandDetail from "@/views/BrandDetail.vue";
import BrandInformation from "@/views/BrandInformation.vue";
import Admins from "@/views/Admins.vue";
import SignIns from "@/views/SignIns.vue";
import Clients from "@/views/Clients.vue";
import PageNotFound from "@/views/PageNotFound.vue";
import Archive from "@/views/Archive.vue";

Vue.use(Router);

export default new Router({
  linkExactActiveClass: "active",
  routes: [
    {
      path: "/dashboard",
      name: "Dashboard",
      component: () => import(/* webpackChunkName: "dashboard-chunk" */ "@/layout/DashboardLayout"),
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: "/",
          name: "dashboard",
          component: Dashboard,
        },
        {
          path: "/coi-requests",
          name: "COI Requests",
          meta: {
            requiresAuth: true,
          },
          component: CoiRequests,
        },
        {
          path: "/increase-requests",
          name: "Increase Requests",
          meta: {
            requiresAuth: true,
          },
          component: IncreaseRequest,
        },
        {
          path: "/start-claim",
          name: "Start Claim",
          meta: {
            requiresAuth: true,
          },
          component: StartClaim,
        },
        {
          path: "/prospects",
          name: "Prospects",
          meta: {
            requiresAuth: true,
          },
          component: BrandInformation,
        },
        {
          path: "/prospects/add",
          name: "Add Prospects",
          meta: {
            requiresAuth: true,
          },
          component: WriteProspects,
        },
        {
          path: "/client/add",
          name: "Add Client",
          meta: {
            requiresAuth: true,
          },
          component: WriteClients,
        },
        {
          path: "/brand-detail/:id",
          name: "Brand Detail",
          meta: {
            requiresAuth: true,
          },
          component: BrandDetail,
        },
        {
          path: "/clients/:id",
          name: "Client Detail",
          meta: {
            requiresAuth: true,
          },
          component: BrandDetail,
        },
        {
          path: "/admins",
          name: "Admins",
          meta: {
            requiresAuth: true,
          },
          component: Admins,
        },
        {
          path: "/sign-ins",
          name: "SignIns",
          meta: {
            requiresAuth: true,
          },
          component: SignIns,
        },
        {
          path: "/clients",
          name: "Clients",
          meta: {
            requiresAuth: true,
          },
          component: Clients,
        },
        {
          path: "/clients/:id/edit",
          name: "Edit Client",
          meta: {
            requiresAuth: true,
          },
          component: WriteProspects,
        },
        {
          path: "/archive",
          name: "Archive",
          meta: {
            requiresAuth: true,
          },
          component: Archive,
        },
        {
          path: "/archive/:id",
          name: "Brand Detail",
          meta: {
            requiresAuth: true,
          },
          component: BrandDetail,
        },
      ],
    },
    {
      path: "/",
      name: "login",
      component: AuthLayout,
      children: [
        {
          path: "/",
          name: "login",
          component: () => import(/* webpackChunkName: "login-chunk" */ "./views/Login.vue"),
        },
      ],
    },
    { path: "/404", name: "Page Not Found", component: PageNotFound },
    { path: "*", redirect: "/404" }
  ],
});
