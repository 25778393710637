<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- Card stats -->
      <div class="row">
        <div class="col-xl-4 col-lg-6">
          <stats-card
            title="COI Requests"
            type="gradient-red"
            :sub-title="`${this.coiRequset.length}`"
            icon="ni ni-active-40"
            class="mb-4 mb-xl-3"
          ></stats-card>
        </div>
        <div class="col-xl-4 col-lg-6">
          <stats-card
            title="Increase Requests"
            type="gradient-orange"
            :sub-title="`${this.increaseRequset.length}`"
            icon="ni ni-chart-pie-35"
            class="mb-4 mb-xl-3"
          >
          </stats-card>
        </div>
        <div class="col-xl-4 col-lg-6">
          <stats-card
            title="Claim Requests"
            type="gradient-green"
            :sub-title="`${this.claim.length}`"
            icon="ni ni-money-coins"
            class="mb-4 mb-xl-3"
          >
          </stats-card>
        </div>
      </div>
    </base-header>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  methods: {
    ...mapActions([
      "fetchClaim",
      "fetchIncreaseRequestes",
      "fetchCoiRequestes",
    ]),
  },
  mounted() {
    this.fetchClaim();
    this.fetchIncreaseRequestes();
    this.fetchCoiRequestes();
  },
  computed: {
    ...mapGetters(["coiRequset", "increaseRequset", "claim"]),
  },
};
</script>
<style></style>
