<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <div class="card shadow">
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0">Prospects</h3>
                </div>
                <div class="col-3">
                  <b-form-select :value="selectedFilter" @change="onFilterSelect">
                    <b-form-select-option value="ALL">All</b-form-select-option>
                    <b-form-select-option-group v-for="group of filterOptions" :key="group.id" :label="group.name">
                      <b-form-select-option v-for="item of group.items" :value="item.value">{{item.text}}</b-form-select-option>
                    </b-form-select-option-group>
                  </b-form-select>
                </div>
                <div class="col-3">
                  <b-button
                    @click="
                      () => {
                        setSelectedBrandFilter(null);
                        $router.push('/prospects/add');
                      }
                    "
                    variant="dark"
                    block
                    >Add Prospect</b-button
                  >
                </div>
              </div>
            </div>
            <div v-if="loader" class="loader text-center">
              <b-spinner
                style="width: 3rem; height: 3rem"
                label="Large Spinner"
              ></b-spinner>
            </div>
            <div v-else class="table-responsive">
              <table class="table align-items-center table-flush">
                <thead>
                  <tr>
                    <th class="sortable" :class="sortField === 'brand_name' ? sortDirection : ''" @click="sortByField('brand_name')">
                      Brand Name
                    </th>
                    <th class="sortable" :class="sortField === 'name' ? sortDirection : ''" @click="sortByField('name')">
                      Individual Name
                    </th>
                    <th>Invitation Status</th>
                    <th>Submitted At</th>
                    <th>view</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-if="!getData.length">
                    <td v-if="selectedFilter !== 'ALL'" colspan="6" align="center">
                      <b>No data available for the specified filters</b>
                    </td>
                    <td v-else colspan="6" align="center">
                      <b>No data available</b>
                    </td>
                  </tr>
                  <tr v-for="brandFilter in getData" :key="brandFilter.id">
                    <th>{{ brandFilter.brand_name }}</th>
                    <td>{{ brandFilter.name }}</td>
                    <td>
                      <status-badge :status="brandFilter.invitation_stage" />
                    </td>
                    <td>
                      <template v-if="brandFilter.createdAtStr">{{ brandFilter.createdAtStr }}</template>
                      <template v-else>
                        <status-badge />
                      </template>
                    </td>
                    <td>
                      <base-button
                        type="primary"
                        size="sm"
                        @click.prevent="expand(brandFilter.id)"
                        >View</base-button
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { INVITATION_STATUSES_DICT, PROSPECTS_STATUSES_DICT } from "@/utils/statusesConstants";
import StatusBadge from "@/components/StatusBadge.vue";
import { getAlphabeticallySortedData } from "@/utils/arrayUtils";

export default {
  name: "BrandInformation",
  components: {
    StatusBadge,
  },
  data() {
    return {
      id: null,
      selectedFilter: "ALL",
      sortField: null,
      sortDirection: null,
      filterOptions: [
        {
          name: 'Invitation Status',
          id: 2,
          items: [
            { value: {2: INVITATION_STATUSES_DICT.sent}, text: "SENT" },
            { value: {2: INVITATION_STATUSES_DICT.accepted}, text: "ACCEPTED" },
            { value: {2: INVITATION_STATUSES_DICT.expired}, text: "EXPIRED" },
          ]
        },
      ],
    };
  },
  methods: {
    ...mapActions(["fetchBrandFilter"]),
    expand(id) {
      this.$router.push("/brand-detail/" + id);
    },
    onFilterSelect(value) {
      this.selectedFilter = value;
    },
    sortByField(field) {
      this.sortField = field;
      this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
    }
  },
  computed: {
    ...mapGetters(["loader", "brandFilterData"]),
    getData() {
      if (this.selectedFilter === 'ALL') {
        return this.sortedProspects;
      }
      const groupId = Object.keys(this.selectedFilter)[0];

      return this.sortedProspects.filter(
        (request) => {
          return groupId === '1' ?
              request.status === this.selectedFilter[groupId] :
              request.invitation_stage === this.selectedFilter[groupId];
        }
      );
    },
    sortedProspects() {
      return getAlphabeticallySortedData(this.brandFilterData, this.sortField, this.sortDirection);
    },
  },
  created() {
    this.fetchBrandFilter();
  },
};
</script>

<style scoped>
.loader {
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}
</style>
