export const NOT_AVAILABLE = 'N/A';
export const ARCHIVED = 'ARCHIVED';


export const INVITATION_STATUSES_DICT = {
  sent: 'SENT',
  accepted: 'ACCEPTED',
  expired: 'EXPIRED',
  notAvailable: NOT_AVAILABLE,
};

export const PROSPECTS_STATUSES_DICT = {
  new: 'NEW',
  processed: 'PROCESSED',
  archived: ARCHIVED
};

export const CLIENTS_STATUSES_DICT = {
  active: 'ACTIVE',
  archived: ARCHIVED
};
