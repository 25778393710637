<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <b-card class="shadow" no-body>
            <b-card-body class="root-card-body">
              <div v-if="globalLoader" class="text-center">
                <b-spinner
                    style="width: 3rem; height: 3rem"
                    label="Large Spinner"
                ></b-spinner>
              </div>
              <b-container v-else>
                <div class="row my-3">
                  <div class="col-lg-8 col-sm-6">
                    <b-card class="collapsable-card" no-body>
                      <template #header>
                        <div class="row" v-b-toggle.collapse-1>
                          <div class="col-6 text left">GENERAL INFO</div>
                          <div class="col-6 text-right">
                            <i class="ni ni-bold-down"></i>
                          </div>
                        </div>
                      </template>
                      <b-collapse visible id="collapse-1">
                        <b-card-text>
                          <div class="row my-3">
                            <div class="col-md-6 col-sm-12">
                              <h4 class="data-title">
                                <b>Product Category:</b>{{" "}}
                                <template v-if="singleBrandDetail.product_category">{{ singleBrandDetail.product_category }}</template>
                                <status-badge v-else :status="notAvailableStatus" />
                              </h4>
                            </div>

                            <div class="col-md-6 col-sm-12">
                              <h4 class="data-title">
                                <b>Product Category Description:</b>{{" "}}
                                <template v-if="singleBrandDetail.product_category_description">{{ singleBrandDetail.product_category_description }}</template>
                                <status-badge v-else :status="notAvailableStatus" />
                              </h4>
                            </div>
                          </div>

                          <div class="row my-3">
                            <div class="col-md-6 col-sm-12">
                              <h4 class="data-title">
                                <b>Product kind description:</b>{{" "}}
                                <template v-if="singleBrandDetail.survey && singleBrandDetail.survey.product_description">{{ singleBrandDetail.survey.product_description }}</template>
                                <status-badge v-else :status="notAvailableStatus" />
                              </h4>
                            </div>
                            <div class="col-md-6 col-sm-12">
                              <h4 class="data-title">
                                <b>States you sell products:</b>{{" "}}
                                <template v-if="singleBrandDetail.how_many_states">{{singleBrandDetail.how_many_states}}</template>
                                <status-badge v-else :status="notAvailableStatus" />
                              </h4>
                            </div>
                          </div>

                          <div class="row my-3">
                            <div class="col-md-6 col-sm-12">
                              <h4 class="data-title">
                                <b>Annual Revenue:</b>{{" "}}
                                <template v-if="singleBrandDetail.annual_revenue">{{ singleBrandDetail.annual_revenue }}</template>
                                <status-badge v-else :status="notAvailableStatus" />
                              </h4>
                            </div>
                            <div class="col-md-6 col-sm-12">
                              <h4 class="data-title"><b>Email:</b> {{ singleBrandDetail.email }}</h4>
                            </div>
                          </div>
                          <div class="row my-3">
                            <div class="col-md-6 col-sm-12">
                              <h4 class="data-title">
                                <b>Number of employees:</b>{{" "}}
                                <template v-if="singleBrandDetail.how_many_employees">{{ singleBrandDetail.how_many_employees }}</template>
                                <status-badge v-else :status="notAvailableStatus" />
                              </h4>
                            </div>
                            <div class="col-md-6 col-sm-12">
                              <h4 class="data-title">
                                <b>Sell online Direct-to-Consumer:</b>{{" "}}
                                <template v-if="singleBrandDetail.sell_online">{{ singleBrandDetail.sell_online }}</template>
                                <status-badge v-else :status="notAvailableStatus" />
                              </h4>
                            </div>
                          </div>
                          <div class="row my-3">
                            <div class="col-md-6 col-sm-12">
                              <h4 class="data-title">
                                <b>Own your manufacturing:</b>{{" "}}
                                <template v-if="singleBrandDetail.own_manufacturing">{{ singleBrandDetail.own_manufacturing }}</template>
                                <status-badge v-else :status="notAvailableStatus" />
                              </h4>
                            </div>
                            <div class="col-md-6 col-sm-12">
                              <h4 class="data-title">
                                <b>State of Primary Operation:</b>{{" "}}
                                <template v-if="singleBrandDetail.state_of_operation">{{ singleBrandDetail.state_of_operation }}</template>
                                <status-badge v-else :status="notAvailableStatus" />
                              </h4>
                            </div>
                          </div>
                          <div class="row mt-3">
                            <div class="col-md-6 col-sm-12">
                              <h4 class="data-title">
                                <b>Ownership Structure:</b>{{" "}}
                                <template v-if="singleBrandDetail.ownership_structure">{{ singleBrandDetail.ownership_structure }}</template>
                                <status-badge v-else :status="notAvailableStatus" />
                              </h4>
                              <h4
                                  class="data-title"
                                  v-if="singleBrandDetail.ownership_structure_description"
                              >
                                <b>Description:</b>
                                {{
                                  singleBrandDetail.ownership_structure_description
                                }}
                              </h4>
                            </div>
                            <div
                                class="col-md-6 col-sm-12"
                                v-if="singleBrandDetail.distribution_description"
                            >
                              <h4 class="data-title">
                                <b>Distribution Description:</b>
                                {{ singleBrandDetail.distribution_description }}
                              </h4>
                            </div>
                          </div>
                          <div class="row mt-3">
                            <div class="col-md-6 col-sm-12">
                              <h4 class="data-title">
                                <b>Fulfillment Description:</b>{{" "}}
                                <template v-if="singleBrandDetail.fulfillment_description">{{ singleBrandDetail.fulfillment_description }}</template>
                                <status-badge v-else :status="notAvailableStatus" />
                              </h4>
                            </div>
                          </div>
                          <div class="row mt-3" v-if="singleBrandDetail.policies">
                            <div class="col-md-6 col-sm-12">
                              <h4 class="data-title">Policies:</h4>
                              <ul class="policies-list" v-if="singleBrandDetail.policies">
                                <li v-for="(file, index) of singleBrandDetail.policies.files" :key="index">
                                  <b-link :href="file.url" target="_blank">
                                    {{file.name}}
                                  </b-link>
                                </li>
                              </ul>
                              <ul
                                  v-if="singleBrandDetail.policies.selected"
                                  class="pl-3 selections-list"
                              >
                                <li
                                    v-for="(selection, index) in singleBrandDetail
                                    .policies.selected"
                                    :key="index"
                                >
                                  <b class="selection">{{ selection }}</b>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </b-card-text>
                      </b-collapse>
                    </b-card>
                    <b-card class="collapsable-card mt-2" no-body v-if="singleBrandDetail.survey">
                      <template #header>
                        <div class="row" v-b-toggle.collapse-2>
                          <div class="col-6 text left">Additional info</div>
                          <div class="col-6 text-right">
                            <i class="ni ni-bold-down"></i>
                          </div>
                        </div>
                      </template>
                      <b-collapse visible id="collapse-2">
                        <b-card-text>
                          <div class="row my-3">
                            <div class="col-md-6 col-sm-12">
                              <h4 class="data-title">
                                <b>Legal business name:</b>
                                {{ singleBrandDetail.survey.legal_business_name }}
                              </h4>
                            </div>
                            <div class="col-md-6 col-sm-12">
                              <h4 class="data-title">
                                <b>Type of Business:</b>
                                {{ singleBrandDetail.survey.business_type }}
                              </h4>
                            </div>
                          </div>
                          <div class="row my-3">
                            <div class="col-md-6 col-sm-12">
                              <h4 class="data-title">
                                <b>Year Founded:</b>
                                {{ singleBrandDetail.survey.year_founded }}
                              </h4>
                            </div>
                          </div>
                          <div class="row my-3">
                            <div class="col-md-6 col-sm-12">
                              <h4 class="data-title">
                                <b>FEIN:</b>
                                {{ singleBrandDetail.survey.fein }}
                              </h4>
                            </div>
                            <div class="col-md-6 col-sm-12">
                              <h4 class="data-title"><b>Phone:</b> {{ singleBrandDetail.survey.phone }}</h4>
                            </div>
                          </div>
                          <div class="row my-3">
                            <div class="col-md-6 col-sm-12">
                              <h4 class="data-title">
                                <b>Mailing address:</b>
                                {{ singleBrandDetail.survey.mailing_address }}
                              </h4>
                            </div>
                            <div class="col-md-6 col-sm-12">
                              <h4 class="data-title">
                                <b>Business address, if different than mailing: </b>
                                <template v-if="singleBrandDetail.survey.mailing_address_business">{{ singleBrandDetail.survey.mailing_address_business }}</template>
                                <status-badge v-else :status="notAvailableStatus" />
                              </h4>
                            </div>
                          </div>
                          <div class="row my-3">
                            <div class="col-md-6 col-sm-12">
                              <h4 class="data-title">
                                <b>Are any ingredients outside traditional:</b>
                                <template v-if="singleBrandDetail.survey.none_traditional_ingredients">{{ singleBrandDetail.survey.none_traditional_ingredients }}</template>
                                <status-badge v-else :status="notAvailableStatus" />
                              </h4>
                            </div>
                            <div class="col-md-6 col-sm-12">
                              <h4 class="data-title">
                                <b>Average Value of Business Property at office if applicable:</b>
                                <template v-if="singleBrandDetail.survey.average_value">{{ singleBrandDetail.survey.average_value }}</template>
                                <status-badge v-else :status="notAvailableStatus" />
                              </h4>
                            </div>
                          </div>
                          <div class="row my-3">
                            <div class="col-md-6 col-sm-12">
                              <h4 class="data-title">
                                <b>Describe manufacturing process:</b>
                                <template v-if="singleBrandDetail.survey.manufacturing_description">{{ singleBrandDetail.survey.manufacturing_description }}</template>
                                <status-badge v-else :status="notAvailableStatus" />
                              </h4>
                            </div>
                            <div class="col-md-6 col-sm-12">
                              <h4 class="data-title">
                                <b>Is any cooking involved:</b>
                                <template v-if="singleBrandDetail.survey.manufacturing_description">{{singleBrandDetail.survey.cooking_involved ? "Yes" : "No"}}</template>
                                <status-badge v-else :status="notAvailableStatus" />
                              </h4>
                            </div>
                          </div>
                          <div class="row my-3">
                            <div class="col-md-6 col-sm-12">
                              <h4 class="data-title">
                                <b>Involved cooking process description:</b>
                                <template v-if="singleBrandDetail.survey.cooking_involved_description">{{singleBrandDetail.survey.cooking_involved_description}}</template>
                                <status-badge v-else :status="notAvailableStatus" />
                              </h4>
                            </div>
                            <div class="col-md-6 col-sm-12">
                              <h4 class="data-title">
                                <b>Describe the primary equipment used in manufacturing process:</b>
                                <template v-if="singleBrandDetail.survey.equipment_used">{{ singleBrandDetail.survey.equipment_used }}</template>
                                <status-badge v-else :status="notAvailableStatus" />
                              </h4>
                            </div>
                          </div>
                          <div class="row my-3">
                            <div class="col-md-6 col-sm-12">
                              <h4 class="data-title">
                                <b>Number of non-owner employees:</b>
                                <template v-if="singleBrandDetail.survey.non_owner_employees">{{singleBrandDetail.survey.non_owner_employees}}</template>
                                <status-badge v-else :status="notAvailableStatus" />
                              </h4>
                            </div>
                            <div class="col-md-6 col-sm-12">
                              <h4 class="data-title">
                                <b>Total Annualized Payroll:</b>
                                <template v-if="singleBrandDetail.survey.total_payroll">{{singleBrandDetail.survey.total_payroll}}</template>
                                <status-badge v-else :status="notAvailableStatus" />
                              </h4>
                            </div>
                          </div>
                          <div class="row my-3">
                            <div class="col-md-6 col-sm-12">
                              <h4 class="data-title">
                                <b>Projected Annual Revenue in Current Year:</b>
                                <template v-if="singleBrandDetail.survey.projected_annual_revenue">$ {{ singleBrandDetail.survey.projected_annual_revenue }} M</template>
                                <status-badge v-else :status="notAvailableStatus" />
                              </h4>
                            </div>
                            <div class="col-md-6 col-sm-12">
                              <h4 class="data-title">
                                <b>Revenue Breakdown (Retail), %:</b>
                                <template v-if="singleBrandDetail.survey.revenue_breakdown_retail">{{ singleBrandDetail.survey.revenue_breakdown_retail }}</template>
                                <status-badge v-else :status="notAvailableStatus" />
                              </h4>
                            </div>
                          </div>
                          <div class="row my-3">
                            <div class="col-md-6 col-sm-12">
                              <h4 class="data-title">
                                <b>Revenue Breakdown (Online), %:</b>
                                <template v-if="singleBrandDetail.survey.revenue_breakdown_online">{{ singleBrandDetail.survey.revenue_breakdown_online }}</template>
                                <status-badge v-else :status="notAvailableStatus" />
                              </h4>
                            </div>
                            <div class="col-md-6 col-sm-12">
                              <h4 class="data-title">
                                <b>Revenue Breakdown (Wholesale), %:</b>
                                <template v-if="singleBrandDetail.survey.revenue_breakdown_wholesale">{{ singleBrandDetail.survey.revenue_breakdown_wholesale }}</template>
                                <status-badge v-else :status="notAvailableStatus" />
                              </h4>
                            </div>
                          </div>
                        </b-card-text>
                      </b-collapse>
                    </b-card>
                    <b-card class="collapsable-card mt-2" no-body v-if="singleBrandDetail.facilities">
                      <template #header>
                        <div class="row" v-b-toggle.collapse-3>
                          <div class="col-6 text left">Facilities</div>
                          <div class="col-6 text-right">
                            <i class="ni ni-bold-down"></i>
                          </div>
                        </div>
                      </template>
                      <b-collapse visible id="collapse-3">
                        <b-card-text>
                          <b-jumbotron v-for="facility of singleBrandDetail.facilities" :key="facility.id">
                            <div class="row my-3">
                              <div class="col-md-6 col-sm-12">
                                <h4 class="data-title">
                                  <b>Type: </b>
                                  <b-badge v-if="facility.type" variant="dark">{{ facility.type }}</b-badge>
                                  <status-badge v-else :status="notAvailableStatus" />
                                </h4>
                              </div>
                              <div class="col-md-6 col-sm-12">
                                <h4 class="data-title">
                                  <b>Address: </b>
                                  <template v-if="facility.address">{{ facility.address }}</template>
                                  <status-badge v-else :status="notAvailableStatus" />
                                </h4>
                              </div>
                            </div>
                            <div class="row my-3">
                              <div class="col-md-6 col-sm-12">
                                <h4 class="data-title">
                                  <b>Value of Business Personal Property at facility: </b>
                                  <template v-if="facility.property_value">{{ facility.property_value }}</template>
                                  <status-badge v-else :status="notAvailableStatus" />
                                </h4>
                              </div>
                              <div class="col-md-6 col-sm-12">
                                <h4 class="data-title">
                                  <b>Describe operations at the facility:</b>
                                  <template v-if="facility.operations_description">{{ facility.operations_description }}</template>
                                  <status-badge v-else :status="notAvailableStatus" />
                                </h4>
                              </div>
                            </div>
                            <div class="row my-3">
                              <div class="col-md-6 col-sm-12">
                                <h4 class="data-title">
                                  <b>Ownership type: </b>
                                  <template v-if="facility.ownership_type">{{ facility.ownership_type }}</template>
                                  <status-badge v-else :status="notAvailableStatus" />
                                </h4>
                              </div>
                              <div v-if="facility.ownership_type === 'owned'" class="col-md-6 col-sm-12">
                                <h4 class="data-title">
                                  <b>Building's construction year: </b>
                                  <template v-if="facility.building_construction_year">{{ facility.building_construction_year }}</template>
                                  <status-badge v-else :status="notAvailableStatus" />
                                </h4>
                              </div>
                              <div v-if="facility.ownership_type === 'owned'" class="col-md-6 col-sm-12">
                                <h4 class="data-title">
                                  <b>Building insurance requirements: </b>
                                  <template v-if="facility.building_insurance_requirements">{{ facility.building_insurance_requirements }}</template>
                                  <status-badge v-else :status="notAvailableStatus" />
                                </h4>
                              </div>
                            </div>
                          </b-jumbotron>
                        </b-card-text>
                      </b-collapse>
                    </b-card>
                  </div>
                  <div class="col-lg-4 col-sm-6">
                    <b-card header="BRAND INFO">
                      <b-card-text>
                        <h4 class="data-title">
                          <b>Brand Name:</b> {{ singleBrandDetail.brand_name }}
                        </h4>
                        <h4 class="data-title">
                          <b>Individual Name:</b> {{ singleBrandDetail.name }}
                        </h4>
                        <div class="status-row data-title">
                          <b>DBA:</b>
                          <template v-if="singleBrandDetail.survey && singleBrandDetail.survey.dba">{{ singleBrandDetail.survey.dba }}</template>
                          <status-badge v-else :status="notAvailableStatus" />
                        </div>
                        <h4 class="status-row">
                          <b>Brand Status:</b> <status-badge :status="singleBrandDetail.status" />
                        </h4>
                        <h4 class="status-row">
                          <b>Invitation Status:</b> <status-badge :status="singleBrandDetail.invitation_stage" />
                        </h4>
                        <div
                            class="d-inline-block"
                            v-b-tooltip.hover="isArchived ? disabledButtonsTooltipText : ''"
                            :title="isArchived ? disabledButtonsTooltipText : ''"
                        >
                          <base-button
                              :tooltipText="isArchived ? disabledButtonsTooltipText : ''"
                              :disabled="isArchived"
                              type="warning"
                              size="sm"
                              @click="
                              () => {
                                this.resetForm(), (modalShow = !modalShow);
                              }
                            "
                          >Assign Policy</base-button
                          >
                        </div>
                      </b-card-text>
                    </b-card>
                    <b-card header="Edit Policy" class="editPolicy mt-2">
                      <b-card-text
                          v-for="(policy, index) in getSingleUsersPolicies"
                          :key="index"
                      >
                        <h4 class="data-title"><b>Policy Title:</b> {{ policy.title }}</h4>
                        <h4 class="data-title"><b>Policy Number</b> {{ policy.number }}</h4>
                        <div
                            class="d-inline-block"
                            v-b-tooltip.hover="isArchived ? disabledButtonsTooltipText : ''"
                            :title="isArchived ? disabledButtonsTooltipText : ''"
                        >
                          <base-button
                              :disabled="isArchived"
                              type="warning"
                              size="sm"
                              @click="edit(policy)"
                          >Edit Policy</base-button>
                        </div>
                        <hr class="my-2" />
                      </b-card-text>
                    </b-card>
                  </div>
                </div>
              </b-container>
            </b-card-body>

            <template #footer>
              <div class="container">
                <div class="buttons">
                  <b-button
                      type="button"
                      @click="archiveUnarchiveHandler"
                      variant="outline-dark"
                      :disabled="globalLoader || archiveUnarchiveButtonLoader"
                  >
                    <b-spinner
                        v-if="archiveUnarchiveButtonLoader"
                        label="button spinner"
                    ></b-spinner>
                    <template v-else>{{ archivationTogglerText }}</template>
                  </b-button>
                  <div class="buttons-group">
                    <div
                        v-for="button of buttons"
                        v-b-tooltip.hover="button.tooltipText"
                        :title="button.tooltipText"
                        :key="button.key"
                    >
                      <b-button
                          type="button"
                          @click="button.clickHandler"
                          :variant="button.variant"
                          :disabled="button.disabled"
                          :class="button.class"
                          v-b-modal="button.modalId"
                      >
                        <b-spinner
                            v-if="button.loader"
                            label="button spinner"
                        ></b-spinner>
                        <template v-else>{{button.text}}</template>
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </b-card>
        </div>
      </div>
    </div>
    <!-- modal -->
    <b-modal
        v-model="modalShow"
        ok-variant="danger"
        size="lg"
        centered
        title="Assign Policy"
        ok-only
        ok-title="Close"
        hide-footer
    >
      <b-form @submit.prevent="onSubmit">
        <div class="row">
          <div class="col-sm-12 col-lg-6">
            <b-form-group
                id="input-group-2"
                label="Policy Title:"
                label-for="title"
            >
              <b-form-input
                  id="title"
                  v-model="form.assigned_policy.title"
                  placeholder="Policy Title/Name"
                  type="text"
                  required
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-sm-12 col-lg-6">
            <b-form-group
                id="input-group-2"
                label="Expiration Date"
                label-for="Date"
            >
              <b-form-input
                  id="Date"
                  v-model="form.assigned_policy.expiration"
                  placeholder="Policy Title/Name"
                  type="date"
                  required
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-sm-12 col-lg-6">
            <b-form-group
                id="input-group-2"
                label="Coverage Limits:"
                label-for="coverage_limit"
            >
              <b-form-input
                  id="coverage_limit"
                  v-model="form.assigned_policy.coverage_limit"
                  placeholder="Coverage Limit"
                  type="text"
                  required
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-sm-12 col-lg-6">
            <b-form-group
                id="input-group-2"
                label="Premium"
                label-for="premium"
            >
              <b-form-input
                  id="premium"
                  v-model="form.assigned_policy.premium"
                  placeholder="Premium"
                  type="text"
                  required
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-sm-12 col-lg-6">
            <b-form-group
                id="input-group-2"
                label="Insurer:"
                label-for="insurer"
            >
              <b-form-input
                  id="insurer"
                  v-model="form.assigned_policy.insurer"
                  placeholder="Insurer"
                  type="text"
                  required
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-sm-12 col-lg-6">
            <b-form-group
                id="input-group-2"
                label="Policy Number"
                label-for="number"
            >
              <b-form-input
                  id="number"
                  v-model="form.assigned_policy.number"
                  placeholder="Policy Number"
                  type="text"
                  required
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-sm-12">
            <b-form-group
                id="input-group-2"
                label="Deductible:"
                label-for="deductible"
            >
              <b-form-input
                  id="deductible"
                  v-model="form.assigned_policy.deductible"
                  placeholder="Deductible"
                  type="number"
                  min="0"
                  max="1000000"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-sm-12 col-lg-6">
            <!-- fileUpload -->
            <b-form-group
                label="Assigned File"
                label-for="assignedFile"
            >
              <b-form-file
                  id="assignedFile"
                  v-model="file"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                  required
              ></b-form-file>
              <div class="mt-3" v-if="file">
                Selected file: {{ file ? file.name : "" }}
              </div>
            </b-form-group>
          </div>
        </div>
        <div class="text-right">
          <b-button type="submit" class="modal-btn" :disabled="getLoader" variant="success">
            <b-spinner class="modal-btn-spinner text-center" v-if="getLoader" />
            <span v-else>Submit</span></b-button
          >
          <b-button variant="danger" @click="modalShow = !modalShow">
            Close</b-button
          >
        </div>
      </b-form>
    </b-modal>
    <b-modal
        v-model="editModalShow"
        size="lg"
        centered
        title="Edit Policy"
        hide-footer
    >
      <b-form @submit.prevent="update">
        <div class="row">
          <div class="col-sm-12 col-lg-6">
            <b-form-group
                id="input-group-2"
                label="Policy Title:"
                label-for="title"
            >
              <b-form-input
                  id="title"
                  v-model="form.assigned_policy.title"
                  placeholder="Policy Title/Name"
                  type="text"
                  required
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-sm-12 col-lg-6">
            <b-form-group
                id="input-group-2"
                label="Expiration Date:"
                label-for="Date"
            >
              <b-form-input
                  id="Date"
                  v-model="form.assigned_policy.expiration"
                  placeholder="Policy Title/Name"
                  type="date"
                  required
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-sm-12 col-lg-6">
            <b-form-group
                id="input-group-2"
                label="Coverage Limits:"
                label-for="coverage_limit"
            >
              <b-form-input
                  id="coverage_limit"
                  v-model="form.assigned_policy.coverage_limit"
                  placeholder="Coverage Limit"
                  type="text"
                  required
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-sm-12 col-lg-6">
            <b-form-group
                id="input-group-2"
                label="Premium:"
                label-for="premium"
            >
              <b-form-input
                  id="premium"
                  v-model="form.assigned_policy.premium"
                  placeholder="Premium"
                  type="text"
                  required
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-sm-12 col-lg-6">
            <b-form-group
                id="input-group-2"
                label="Insurer:"
                label-for="insurer"
            >
              <b-form-input
                  id="insurer"
                  v-model="form.assigned_policy.insurer"
                  placeholder="Insurer"
                  type="text"
                  required
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-sm-12 col-lg-6">
            <b-form-group
                id="input-group-2"
                label="Policy Number:"
                label-for="number"
            >
              <b-form-input
                  id="number"
                  v-model="form.assigned_policy.number"
                  placeholder="Policy Number"
                  type="text"
                  required
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-sm-12">
            <b-form-group
                id="input-group-2"
                label="Deductible:"
                label-for="deductible"
            >
              <b-form-input
                  id="deductible"
                  v-model="form.assigned_policy.deductible"
                  placeholder="Deductible"
                  type="number"
                  min="0"
                  max="1000000"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-sm-12 col-lg-6">
            <!-- fileUpload -->
            <b-form-group
                id="input"
                label="Insurance Policy File:"
                label-for="file"
            >
              <div class="mb-3" v-if="selectedFile">
                Assigned File:
                <a class="ml-3" :href="selectedFile" target="_blank">
                  <i class="ni ni-folder-17 "></i>
                </a>
              </div>
              <b-form-file
                id="file"
                v-model="file"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              ></b-form-file>
              <div class="mt-3" v-if="file">
                Selected file: {{ file ? file.name : "" }}
              </div>
            </b-form-group>
          </div>
        </div>
        <div class="text-right">
          <b-button type="submit" class="modal-btn" :disabled="getLoader" variant="success">
            <b-spinner class="modal-btn-spinner text-center" v-if="getLoader" />
            <span v-else>Update</span></b-button
          >
          <b-button variant="danger" @click="editModalShow = !editModalShow">
            Close</b-button
          >
        </div>
      </b-form>
    </b-modal>
    <!-- Make client from prospect modal -->
    <b-modal
        v-model="isMoveToClientsModalVisible"
        title="Confirm your action"
        centered
        hide-footer
    >
      <p class="mb-5">If you confirm, we will create a Client from this Prospect.</p>
      <div class="text-right confirmation-modal-buttons">
        <b-button type="button"
                  @click="moveToClientsConfirmed"
                  variant="success"
                  :disabled="moveToClientsConfirmBtnLoader"
        >
          <b-spinner class="modal-btn-spinner" v-if="moveToClientsConfirmBtnLoader" />
          <span v-else>Confirm</span>
        </b-button>
        <b-button variant="danger"
                  :disabled="moveToClientsConfirmBtnLoader"
                  @click="isMoveToClientsModalVisible = !isMoveToClientsModalVisible"
        >
          Cancel
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {mapGetters, mapActions, mapMutations, createNamespacedHelpers} from "vuex";
import {ARCHIVED, INVITATION_STATUSES_DICT, NOT_AVAILABLE, PROSPECTS_STATUSES_DICT} from "@/utils/statusesConstants";
import {BRAND_ACTION_DICTIONARY} from "@/utils/brandActionDictionary";
import StatusBadge from "@/components/StatusBadge.vue";

const {
  mapGetters: invitationsModuleGetters,
  mapActions: invitationsModuleActions,
} = createNamespacedHelpers('invitations');

export default {
  name: "ExtandedView",
  components: {
    StatusBadge,
  },
  data() {
    return {
      notAvailableStatus: NOT_AVAILABLE,
      disabledButtonsTooltipText: 'Need to unarchive the brand first.',
      clickedButtonName: '',
      editDocId: null,
      assign_policy: true,
      modalShow: false,
      editModalShow: false,
      isMoveToClientsModalVisible: false,
      file: null,
      selectedFile: null,
      form: {
        assigned_policy: {
          title: "",
          expiration: "",
          coverage_limit: "",
          premium: "",
          insurer: "",
          number: "",
          deductible: null
        },
      },
    };
  },
  methods: {
    ...mapMutations(['setExists']),
    ...mapMutations(['setError', 'setSuccessMessage']),
    ...mapActions([
      "postRequset",
      "getSingleClientDetails",
      "getSingleBrandDetail",
      "changeBrandStatus",
      "getAssignedPolicies",
      "changeClientStatus",
      "checkClientExists"
    ]),
    ...invitationsModuleActions(["sendInvitation"]),
    watchForBrandDetails() {
      const { query: {sent}, params: {id}  } = this.$route;

      if (!id || !sent) {
        return;
      }

      this.unsubscribe = this.$watch('singleBrandDetail', (newState) => {
        if (!Object.keys(newState).length) {
          return;
        }
        this.setSuccessMessage(`
          Client was successfully created and
          an invitation was sent to ${this.singleBrandDetail.email}.
        `);
        this.$router.replace({
          name: "Client Detail",
          params: { id }
        });
        this.unsubscribe();
      });
    },
    async toggleArchiveUnarchiveClient() {
      const { id } = this.$route.params;

      if (!id) {
        return;
      }

      this.clickedButtonName = BRAND_ACTION_DICTIONARY.archiveUnarchive;
      await this.changeClientStatus({id});
      await this.getSingleClientDetails(id);
    },
    async toggleArchiveUnarchiveProspect() {
      const { id } = this.$route.params;

      if (!id) {
        return;
      }

      this.clickedButtonName = BRAND_ACTION_DICTIONARY.archiveUnarchive;

      const notArchived = this.singleBrandDetail.status !== PROSPECTS_STATUSES_DICT.archived;

      if (notArchived) {
        await this.changeBrandStatus({
          id,
          onSuccessMessage: `Brand with name
          ${this.singleBrandDetail.brand_name}
          ${notArchived ? 'archived' : 'unarchived'}
          successfully.
        `,
          statusToSet: notArchived ? PROSPECTS_STATUSES_DICT.archived : PROSPECTS_STATUSES_DICT.new,
          fieldName: 'status'
        });

        await this.getSingleBrandDetail(id);
        return;
      }

      await this.checkClientExists(id);
    },
    handleErrors(newState) {
      if (!newState) return;

      this.showToast('danger', newState, 'An error occurred.');
    },
    showToast(variant = null, message = '', title = '') {
      this.$bvToast.toast(message, {
        title,
        variant,
        solid: true
      })
    },
    moveToProcessedHandler() {
      this.isMoveToClientsModalVisible = true;
    },
    async moveToClientsConfirmed() {
      const { id } = this.$route.params;

      if (!id) {
        return;
      }

      this.clickedButtonName = BRAND_ACTION_DICTIONARY.moveToProcessed;

      await this.changeBrandStatus({
        email: this.singleBrandDetail.email,
        id,
        onSuccessMessage: `Brand with name ${this.singleBrandDetail.brand_name} moved to 'PROCESSED' status successfully.`,
        statusToSet: PROSPECTS_STATUSES_DICT.processed,
        fieldName: 'status'
      });

      this.isMoveToClientsModalVisible = false;

      await this.getSingleBrandDetail(id);
    },
    async sendInvitationHandler() {
      const { id } = this.$route.params;

      if (!id) {
        return;
      }

      this.clickedButtonName = BRAND_ACTION_DICTIONARY.sendInvitation;

      await this.sendInvitation({id}, {root: true});
      await this.getSingleBrandDetail(id);
    },
    resetForm() {
      (this.selectedFile = null),
        (this.file = null),
        (this.form.assigned_policy = {
          title: "",
          expiration: "",
          coverage_limit: "",
          premium: "",
          insurer: "",
          number: "",
          deductible: null
        });
    },
    async onSubmit() {
      let form = this.form.assigned_policy;
      let id = this.singleBrandDetail.id;
      let file = this.file;
      let resp = await this.postRequset({ form, id, file });
      if (resp.success === true) {
        this.modalShow = !this.modalShow;
        this.resetForm();
        await this.getAssignedPolicies(this.$route.params.id)
      }
    },
    edit(policy) {
      this.editModalShow = !this.editModalShow;
      this.editDocId = policy.docId;
      this.form.assigned_policy.title = policy.title;
      this.form.assigned_policy.expiration = policy.expiration;
      this.form.assigned_policy.coverage_limit = policy.coverage_limit;
      this.form.assigned_policy.premium = policy.premium;
      this.form.assigned_policy.insurer = policy.insurer;
      this.form.assigned_policy.number = policy.number;
      this.form.assigned_policy.deductible = policy.deductible;
      this.selectedFile = policy.assigned_file;
    },
    async update() {
      let id = this.editDocId;
      let form = this.form.assigned_policy;
      let file = this.file;
      let resp = await this.editPolicy({ id, form, file });
      if (resp.success === true) {
        this.editModalShow = !this.editModalShow;
        await this.getAssignedPolicies(this.$route.params.id);
        this.resetForm();
      }
    },
  },

  computed: {
    ...mapGetters(["singleBrandDetail", "getLoader", "loader", "globalLoader", "error", "successMessage", "clientExists"]),
    ...invitationsModuleGetters({ invitationsError: "error", invitationsLoader: "loader" }),
    isArchived() {
      return this.singleBrandDetail.status === ARCHIVED;
    },
    archiveUnarchiveHandler() {
      return this.isClientDetailPage ? this.toggleArchiveUnarchiveClient : this.toggleArchiveUnarchiveProspect
    },
    isInvitationButtonDisabled() {
      return this.invitationsLoader || (
          this.singleBrandDetail.status === PROSPECTS_STATUSES_DICT.archived ||
          this.singleBrandDetail.invitation_stage === INVITATION_STATUSES_DICT.sent ||
          this.singleBrandDetail.invitation_stage === INVITATION_STATUSES_DICT.accepted
      );
    },
    moveToClientsConfirmBtnLoader() {
      return this.clickedButtonName === BRAND_ACTION_DICTIONARY.moveToProcessed && this.loader;
    },
    isMoveToProcessedButtonDisabled() {
      return this.singleBrandDetail.status === PROSPECTS_STATUSES_DICT.processed ||
          this.singleBrandDetail.status === PROSPECTS_STATUSES_DICT.archived;
    },
    archiveUnarchiveButtonLoader() {
      return this.clickedButtonName === BRAND_ACTION_DICTIONARY.archiveUnarchive && this.loader;
    },
    archivationTogglerText() {
      return this.singleBrandDetail.status === PROSPECTS_STATUSES_DICT.archived ?
          "Unarchive" :
          "Archive";
    },
    buttons() {
      let list = [
        {
          tooltipText: this.singleBrandDetail.status === PROSPECTS_STATUSES_DICT.archived ? this.disabledButtonsTooltipText : '',
          variant: 'success',
          loader: this.clickedButtonName === BRAND_ACTION_DICTIONARY.sendInvitation && this.invitationsLoader,
          text: 'Send Invitation',
          clickHandler: this.sendInvitationHandler,
          disabled: this.globalLoader || this.isInvitationButtonDisabled,
          key: 1,
        }
      ];

      if (!this.isClientDetailPage) {
        list = [
          ...list,
          {
            tooltipText: this.singleBrandDetail.status === PROSPECTS_STATUSES_DICT.archived ? this.disabledButtonsTooltipText : '',
            variant: 'warning',
            text: 'Move to Processed',
            clickHandler: this.moveToProcessedHandler,
            disabled: this.globalLoader || this.isMoveToProcessedButtonDisabled,
            class: 'move-to-processed-btn',
            key: 2,
          }
        ];
      }

      return list;
    },
    isClientDetailPage() {
      const {name, query} = this.$route;

      return name === "Client Detail" || query.client;
    },
  },

  created() {
    const { id } = this.$route.params;
    if (this.isClientDetailPage) {
      this.getSingleClientDetails(id);
      return;
    }

    this.getSingleBrandDetail(id);
  },

  mounted() {
    this.setSuccessMessage('');
    this.setError('');

    if (this.isClientDetailPage) {
      this.watchForBrandDetails();
    }
  },

  watch: {
    error: {
      handler: 'handleErrors'
    },
    invitationsError: {
      handler: 'handleErrors'
    },
    successMessage(newState) {
      if (!newState) return;

      this.showToast('success', newState, 'Action succeeded.');
    },
    clientExists: {
      async handler(newState) {
        if (newState === undefined) return;

        const id = this.singleBrandDetail.id;

        await this.changeBrandStatus({
          id,
          onSuccessMessage: `Brand with name ${this.singleBrandDetail.brand_name} unarchived successfully.`,
          statusToSet: newState ? PROSPECTS_STATUSES_DICT.processed : PROSPECTS_STATUSES_DICT.new,
          fieldName: 'status'
        });

        await this.getSingleBrandDetail(id);
        this.setExists(undefined);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.status-row {
  display: flex;
  align-items: center;
  gap: 5px;

  &:not(h4) {
    margin-bottom: 8px;

    b {
      font-size: 15px;
      color: #32325d;
    }
  }
}

.buttons-group {
  display: flex;
  gap: 0.5rem;
}

.collapsable-card {
  border-bottom-width: 0;
  overflow: hidden;
}

.collapse {
  padding: 0 1.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 0.375rem;

  .card-text {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}

.root-card-body {
  padding: 0.6rem;
  min-height: 662px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-footer {
  padding-left: 0.7rem;
  padding-right: 0.7rem;

  .buttons {
    display: flex;
    justify-content: space-between;
  }

  .btn {
    min-width: 147px;
    min-height: 43px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .move-to-processed-btn {
    min-width: 175px;
  }
}

.editPolicy {
  & .card-body {
    height: 240px;
    overflow-y: auto;
  }
}

.btn-box {
  padding-top: 32px;
  display: flex;
  gap: 24px;
  justify-content: flex-end;

  .alert {
    margin-bottom: 0;
    max-height: 43px;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    flex: 1;
  }
}
.confirmation-modal-buttons {
  .btn-success {
    min-height: 43px;
    padding-top: 0;
    padding-bottom: 0;
    min-width: 99px;
  }
}
.policies-list {
  list-style: none;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  padding: 0;
  font-size: 15px;
}

.selections-list {
  color: #32325d;
  font-size: 15px;
}

.selection {
  font-weight: bold;
}

.modal-btn {
  min-width: 92px;
}

.btn:disabled {
  cursor: not-allowed;
}

.modal-btn-spinner {
  width: 1rem;
  height: 1rem;
}

.jumbotron {
  padding: 0.6rem 1rem 0;
  overflow: hidden;
}
.data-title {
  font-weight: 400;
}
</style>
