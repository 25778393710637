export function getAlphabeticallySortedData(data, field, direction) {
  if (!field) {
    return data;
  }

  return data.sort((a, b) => {
    return direction === "asc"
      ? a[field].localeCompare(b[field])
      : b[field].localeCompare(a[field]);
  });
}
