import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      myrules: {
        required: (value) => !!value || "Required.",
        email: (value) => /.+@.+\..+/.test(value) || "Must be Email",
        alphanumeric: (value) =>
          /^[a-z0-9]+$/i.test(value) || "Only aphanumeric values allowed",
        spaces: (value) =>
          (value || "").indexOf(" ") < 0 || "No spaces are allowed",
      },
    };
  },
  computed: {
    ...mapGetters([
      "user",
      "loggedIn",
      "authLoader",
      "getSingleUsersPolicies",
      "super_admins",
      "getAddUserLoader",
      "getChangeStatusLoader",
      "selectedBrandFilter",
      "signInUsers",
      "newProspectId",
      "resetLoader",
    ]),
  },
  methods: {
    ...mapActions([
      "logIn",
      "register",
      "logOut",
      "editPolicy",
      "getSuper_admin",
      "addAdmin",
      "editAdmin",
      "changeStatus",
      "setSelectedBrandFilter",
      "updateClient",
      "addProspects",
      "listUsers",
      "postRequset",
      "resetPassword",
      "fetchCoiRequestes",
      "fetchIncreaseRequestes",
      "fetchClaim",
    ]),
  },
};
