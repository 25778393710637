<template>
  <div>
    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.companyInfo>
          Please tell us a bit more about your brand: Company information
        </b-button>
      </b-card-header>
      <b-collapse id="companyInfo" role="tabpanel">
        <b-card-body>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <label class="form-control-label">
                  Legal Business Name *
                </label>
                <b-form-input
                    type="text"
                    placeholder="Write legal business name"
                    v-model="brandInfo.legal_business_name"
                    required
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label class="form-control-label">
                  Year Founded *
                </label>
                <b-form-input
                    type="number"
                    v-model="brandInfo.year_founded"
                    min="1900"
                    :max="currentYear"
                    :placeholder="'Write year from 1900 to ' + currentYear"
                    required
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label class="form-control-label">
                  Type of Business *
                </label>
                <b-select
                    type="select"
                    v-model="brandInfo.business_type"
                    :options="typeOfBusiness"
                    required
                ></b-select>
              </b-form-group>
            </b-col>
            <b-col v-if="showDuplicated" md="6">
              <b-form-group>
                <label class="form-control-label">
                  Ownership Structure
                </label>
                <b-select
                    type="select"
                    v-model="brandInfo.ownership_structure"
                    :options="ownershipStructure"
                ></b-select>
              </b-form-group>
            </b-col>
            <b-col
                v-if="showDuplicated && brandInfo.ownership_structure === 'Venture Capital/Private Equity Investment'"
                md="6"
            >
              <b-form-group>
                <label class="form-control-label">
                  Ownership Structure Description *
                </label>
                <b-form-textarea
                    v-model="brandInfo.ownership_structure_description"
                    no-resize
                    rows="3"
                    placeholder="Describe your ownership structure"
                    required
                ></b-form-textarea>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label class="form-control-label">
                  FEIN *
                </label>
                <b-form-input
                    type="text"
                    placeholder="Write FEIN (e.g. 12-3456789)"
                    v-model="brandInfo.fein"
                    pattern="^\d{2}-\d{7}$"
                    required
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label class="form-control-label">
                  Phone *
                </label>
                <b-form-input
                    type="tel"
                    placeholder="Write your phone (e.g. +12223334444)"
                    v-model="brandInfo.phone"
                    pattern="^\+?[1-9][0-9]{7,14}$"
                    required
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label class="form-control-label">
                  Mailing Address *
                </label>
                <b-form-input
                    type="text"
                    v-model="brandInfo.mailing_address"
                    placeholder="Write address"
                    required
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label class="form-control-label">
                  Business address, if different than mailing
                </label>
                <b-form-input
                    type="text"
                    v-model="brandInfo.mailing_address_business"
                    placeholder="Write address"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col v-if="showDuplicated" md="6">
              <b-form-group>
                <label class="form-control-label">
                  State of Primary Operation *
                </label>
                <b-select
                    type="select"
                    v-model="brandInfo.state_of_operation"
                    :options="stateOfPrimaryOperation"
                    required
                ></b-select>
              </b-form-group>
            </b-col>
            <b-col v-if="showDuplicated" md="6">
              <b-form-group>
                <label class="form-control-label">
                  How many States do you sell products in?
                </label>
                <b-select
                    type="select"
                    v-model="brandInfo.how_many_states"
                    :options="howManyStatesYouSell"
                ></b-select>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
      </b-collapse>
    </b-card>
    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.productInfo>
          Please tell us a bit more about your brand: Product
        </b-button>
      </b-card-header>
      <b-collapse id="productInfo" role="tabpanel">
        <b-card-body>
          <b-row>
            <b-col v-if="showDuplicated" md="6">
              <b-form-group>
                <label class="form-control-label">
                  Your Product Category
                </label>
                <b-select
                    type="select"
                    v-model="brandInfo.product_category"
                    :options="productCategories"
                ></b-select>
              </b-form-group>
            </b-col>
            <b-col v-if="showDuplicated && brandInfo.product_category === 'Other'" md="6">
              <b-form-group>
                <label class="form-control-label">
                  Product Category Description *
                </label>
                <b-form-textarea
                    v-model="brandInfo.product_category_description"
                    no-resize
                    rows="3"
                    placeholder="Describe your product category"
                    required
                ></b-form-textarea>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <label class="form-control-label">
                  What Kind of Product do you make
                </label>
                <b-form-textarea
                    v-model="brandInfo.product_description"
                    no-resize
                    rows="3"
                    placeholder="Describe what kind of product do you make"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <label class="form-control-label">
                  Are any ingredients outside “traditional” Food/Beverage/Beauty (e.g., CBD, Supplements, Alcohol)
                </label>
                <b-form-input
                    type="text"
                    placeholder="Write any ingredients outside “traditional”"
                    v-model="brandInfo.none_traditional_ingredients"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <label class="form-control-label">
                  Average Value of Business Property at office if applicable
                </label>
                <b-input-group prepend="$">
                  <b-form-input
                      type="number"
                      min="0"
                      placeholder="Write average value of business property at office"
                      v-model="brandInfo.average_value"
                  >
                  </b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
      </b-collapse>
    </b-card>
    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.facilitiesInfo>
          Please tell us a bit more about your brand: Facilities
        </b-button>
      </b-card-header>
      <b-collapse id="facilitiesInfo" role="tabpanel">
        <ul class="list-group list-group-flush">
          <li class="list-group-item px-4 pt-4 pb-0" v-for="(facility, index) in facilities" :key="index">
            <b-row>
              <b-col md="6">
                <b-form-group>
                  <label class="form-control-label">
                    Select which type of facility
                  </label>
                  <b-form-radio-group v-model="facility.type">
                    <b-form-radio value="manufacturing">
                      Manufacturing
                    </b-form-radio>
                    <b-form-radio value="warehouse">
                      Warehouse
                    </b-form-radio>
                  </b-form-radio-group>
                </b-form-group>
              </b-col>
              <b-col md="auto" class="ml-auto">
                <b-button v-if="index > 0" variant="danger" class="py-2 px-3" @click="deleteFacility(index)">
                  <i class="fas fa-trash-alt fa-lg"></i>
                </b-button>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <label class="form-control-label">
                    Address of facility
                  </label>
                  <b-form-input
                      type="text"
                      placeholder="Write address of facility"
                      v-model="facility.address"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <label class="form-control-label">
                    Value of Business Property at facility
                  </label>
                  <b-input-group prepend="$">
                    <b-form-input
                        type="number"
                        min="0"
                        placeholder="Write value of business property at facility"
                        v-model="facility.property_value"
                    >
                    </b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group>
                  <label class="form-control-label">
                    Describe operations at the facility
                  </label>
                  <b-form-input
                      type="text"
                      placeholder="Describe operations at the facility"
                      v-model="facility.operations_description"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group>
                  <label class="form-control-label">
                    Ownership Type
                  </label>
                  <b-form-radio-group v-model="facility.ownership_type">
                    <b-form-radio value="owned">
                      Owned
                    </b-form-radio>
                    <b-form-radio value="leased">
                      Leased
                    </b-form-radio>
                    <b-form-radio value="third-party">
                      Third-party
                    </b-form-radio>
                  </b-form-radio-group>
                </b-form-group>
              </b-col>
              <b-col v-if="facility.ownership_type === 'owned'" md="12">
                <b-form-group>
                  <label class="form-control-label">
                    Building's construction year *
                  </label>
                  <b-form-input
                      type="number"
                      v-model="facility.building_construction_year"
                      min="1900"
                      :max="currentYear"
                      :placeholder="'Write year from 1900 to ' + currentYear"
                      required
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col v-if="facility.ownership_type === 'owned'" md="12">
                <b-form-group>
                  <label class="form-control-label">
                    Building insurance requirements *
                  </label>
                  <b-form-textarea
                      v-model="facility.building_insurance_requirements"
                      no-resize
                      rows="3"
                      placeholder="Describe your building insurance requirements"
                      required
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>
          </li>
          <li class="list-group-item px-4 py-4">
            <b-button class="mr-0 py-2 px-3" variant="primary" @click="addFacility">
              Add a new facility
            </b-button>
          </li>
        </ul>
      </b-collapse>
    </b-card>
    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.generalInfo>
          Please tell us a bit more about your brand: General
        </b-button>
      </b-card-header>
      <b-collapse id="generalInfo" role="tabpanel">
        <b-card-body>
          <b-row>
            <b-col md="12">
              <b-form-group>
                <label class="form-control-label">
                  Describe your manufacturing process
                </label>
                <b-form-textarea
                    v-model="brandInfo.manufacturing_description"
                    no-resize
                    rows="3"
                    placeholder="Describe your manufacturing process"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <label class="form-control-label">
                  Is any cooking involved?
                </label>
                <b-form-radio-group>
                  <b-form-radio v-model="brandInfo.cooking_involved" :value="true">
                    Yes
                  </b-form-radio>
                  <b-form-radio v-model="brandInfo.cooking_involved" :value="false">
                    No
                  </b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col>
            <b-col md="12" v-if="brandInfo.cooking_involved">
              <b-form-group>
                <label class="form-control-label">
                  Add more information about the cooking process
                </label>
                <b-form-textarea
                    v-model="brandInfo.cooking_involved_description"
                    no-resize
                    rows="3"
                    placeholder="Add more information about the cooking process"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <label class="form-control-label">
                  Describe the primary equipment used in manufacturing process
                </label>
                <b-form-textarea
                    v-model="brandInfo.equipment_used"
                    no-resize
                    rows="3"
                    placeholder="Describe the primary equipment used in manufacturing process"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label class="form-control-label">
                  Number of non-owner employees
                </label>
                <b-form-input
                    type="number"
                    min="0"
                    placeholder="Write number of non-owner employees"
                    v-model="brandInfo.non_owner_employees"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label class="form-control-label">
                  Total Annualized Payroll
                </label>
                <b-input-group prepend="$">
                  <b-form-input
                      type="number"
                      min="0"
                      placeholder="Write total annualized payroll"
                      v-model="brandInfo.total_payroll"
                  >
                  </b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <label class="form-control-label">
                  Projected Annual Revenue in Current Year *
                </label>
                <b-input-group prepend="$">
                  <b-form-input
                      required
                      v-model="brandInfo.projected_annual_revenue"
                      min="0"
                      placeholder="Write annual revenue"
                      type="number"
                  >
                  </b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label class="form-control-label">
                  Revenue Breakdown (Retail), %
                </label>
                <b-form-input
                    type="text"
                    placeholder="Write revenue breakdown (retail)"
                    v-model="brandInfo.revenue_breakdown_retail"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label class="form-control-label">
                  Revenue Breakdown (Online), %
                </label>
                <b-form-input
                    type="text"
                    placeholder="Write revenue breakdown (online)"
                    v-model="brandInfo.revenue_breakdown_online"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <label class="form-control-label">
                  Revenue Breakdown (Wholesale), %
                </label>
                <b-form-input
                    type="text"
                    placeholder="Write revenue breakdown (wholesale)"
                    v-model="brandInfo.revenue_breakdown_wholesale"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>
<script>
export default {
  name: "BrandInformationExtended",
  props: {
    showDuplicated: true,
    initialData: Object
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    }
  },
  data() {
    return {
      productCategories: [
        "Food",
        "Beverage",
        "Alcohol",
        "Household",
        "Hard Goods",
        "Cannabis",
        "Pet",
        "Baby",
        "Other",
      ],
      typeOfBusiness: [ "C-Corp", "S-Corp", "LLC", "Other",],
      ownershipStructure: [
        "Self-Funded and Owned",
        "Family and Friends Owned",
        "Venture Capital/Private Equity Investment",
      ],
      stateOfPrimaryOperation: [
        "Alabama",
        "Alaska",
        "Arizona",
        "Arkansas",
        "California",
        "Colorado",
        "Connecticut",
        "Delaware",
        "Florida",
        "Georgia",
        "Hawaii",
        "Idaho",
        "Illinois",
        "Indiana",
        "Iowa",
        "Kansas",
        "Kentucky",
        "Louisiana",
        "Maine",
        "Maryland",
        "Massachusetts",
        "Michigan",
        "Minnesota",
        "Mississippi",
        "Missouri",
        "Montana",
        "Nebraska",
        "Nevada",
        "New Hampshire",
        "New Jersey",
        "New Mexico",
        "New York",
        "North Carolina",
        "North Dakota",
        "Ohio",
        "Oklahoma",
        "Oregon",
        "Pennsylvania",
        "Rhode Island",
        "South Carolina",
        "South Dakota",
        "Tennessee",
        "Texas",
        "Utah",
        "Vermont",
        "Virginia",
        "Washington",
        "West Virginia",
        "Wisconsin",
        "Wyoming",
      ],
      howManyStatesYouSell: [
        "1-5",
        "6-15",
        "16-30",
        "Nationally",
        "Internationally",
      ],
      brandInfo: {
        // Company Information
        legal_business_name: "",
        year_founded: null,
        business_type: "",
        ownership_structure: null,
        ownership_structure_description: "",
        fein: "",
        phone: "",
        mailing_address: "",
        how_many_states: null,
        state_of_operation: null,
        mailing_address_business: "",

        // Product
        product_category: null,
        product_category_description: "",
        product_description: "",
        none_traditional_ingredients: "",
        average_value: null,

        // General
        manufacturing_description: "",
        cooking_involved: null,
        cooking_involved_description: "",
        equipment_used: "",
        non_owner_employees: "",
        total_payroll: null,
        projected_annual_revenue: null,
        revenue_breakdown_retail: "",
        revenue_breakdown_online: "",
        revenue_breakdown_wholesale: "",
      },
      facilities: [],
      deletedFacilityIds: []
    }
  },
  watch: {
    initialData() {
      this.brandInfo = this.initialData;
      if (this.initialData.facilities) {
        this.facilities = this.initialData.facilities;
      }
    },
    brandInfo: {
      handler() {
        this.updateForm();
      },
      deep: true
    },
    facilities: {
      handler() {
        this.updateForm();
      },
      deep: true
    }
  },
  methods: {
    updateForm(facilityChanged = false) {
      this.$emit("change", {
        data: {
          ...this.brandInfo,
          facilities: this.facilities
        },
        deletedFacilityIds: this.deletedFacilityIds,
        facilityChanged
      });
    },
    addFacility() {
      this.facilities.push({
        address: "",
        operations_description: "",
        ownership_type: "",
        building_construction_year: null,
        building_insurance_requirements: null,
        property_value: null,
        type: ""
      });
      this.updateForm(true);
    },
    deleteFacility(index) {
      const facility = this.facilities.splice(index, 1)[0];
      if (facility.id) {
        this.deletedFacilityIds.push(facility.id);
      }
      this.updateForm(true);
    },
  },
};
</script>
