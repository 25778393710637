import Repository from './Repository';
import qs from 'qs';
const ADD = '/addUser';
const EDIT = '/editUser';
const DISABLE = '/disableUser';

export default {
	addUser(payload) {
		return Repository.post(`${ADD}`, payload);
	},
	editUser(payload) {
		return Repository.post(`${EDIT}`, qs.stringify(payload));
	},
	disableUser(payload) {
		return Repository.post(`${DISABLE}`, qs.stringify(payload));
	},
	listUsers() {
		return Repository.get(`/getSignInUsers`);
	},
	sendProfileCreateEmail(payload) {
		return Repository.post('/sendProfileCreateEmail', payload);
	},
};
