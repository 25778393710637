<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>
    <div class="container-fluid mt--7">
      <div class="card shadow">
        <div class="card-header border-0">
          <div class="row align-items-center">
            <div class="col">
              <h3 class="mb-0">Archive</h3>
            </div>
          </div>
        </div>
        <div v-if="loader" class="loader text-center">
          <b-spinner
            style="width: 3rem; height: 3rem"
            label="Large Spinner"
          ></b-spinner>
        </div>
        <div v-else class="table-responsive">
          <table class="table align-items-center table-flush">
            <thead>
              <tr>
                <th class="sortable" :class="sortField === 'brand_name' ? sortDirection : ''" @click="sortByField('brand_name')">
                  Brand Name
                </th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="!tableData || !tableData.length">
                <td colspan="6" align="center">
                  <b>No data available</b>
                </td>
              </tr>
              <tr v-for="(brandFilter, index) in tableData" :key="brandFilter.id">
                <th>{{ brandFilter.brand_name }}</th>
                <td>
                  <base-button
                    type="primary"
                    size="sm"
                    @click.prevent="expand(brandFilter.id, brandFilter.isClient)"
                  >
                    View
                  </base-button>
                  <base-button
                    :style="{minWidth: '79px'}"
                    type="outline-dark"
                    size="sm"
                    @click.prevent="unarchive(brandFilter.id, brandFilter.brand_name, index, brandFilter.isClient)"
                  >
                    Unarchive
                  </base-button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import StatusBadge from "@/components/StatusBadge.vue";
import {PROSPECTS_STATUSES_DICT} from "@/utils/statusesConstants";
import {getAlphabeticallySortedData} from "@/utils/arrayUtils";

export default {
  name: "Archive",
  components: {StatusBadge},
  data() {
    return {
      sortField: null,
      sortDirection: null,
    }
  },
  methods: {
    ...mapMutations(["setError", "setSuccessMessage"]),
    ...mapActions([
      "fetchArchivedBrandFilters",
      "changeBrandStatus",
      "changeClientStatus"
    ]),
    showToast(variant = null, message = '', title = '') {
      this.$bvToast.toast(message, {
        title,
        variant,
        solid: true
      })
    },
    sortByField(field) {
      this.sortField = field;
      this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
    },
    expand(id, isClient) {
      this.$router.push(`/archive/${id}${isClient ? `?client=true` : ""}`);
    },
    handleErrors(newState) {
      if (!newState) return;

      this.showToast('danger', newState, 'An error occurred.');
    },
    async unarchive(id, brandName, index, isClient) {
      if (isClient) {
        await this.changeClientStatus({id});
      } else {
        await this.changeBrandStatus({
          id,
          onSuccessMessage: `Brand with name ${brandName} unarchived successfully.`,
          statusToSet: PROSPECTS_STATUSES_DICT.new,
          fieldName: 'status'
        });
      }

      await this.fetchArchivedBrandFilters();
    }
  },
  created() {
    this.fetchArchivedBrandFilters();
  },
  async mounted() {
    this.setSuccessMessage('');
    this.setError('');
  },
  computed: {
    ...mapGetters(["getArchivedBrands", "loader", "error", "successMessage"]),
    tableData() {
      return getAlphabeticallySortedData(this.getArchivedBrands, this.sortField, this.sortDirection);
    }
  },
  watch: {
    error: {
      handler: 'handleErrors'
    },
    successMessage(newState) {
      if (!newState) return;

      this.showToast('success', newState, 'Action succeeded.');
    },
  }
}
</script>
