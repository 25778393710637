import Repository from './Repository';

const GET_PROSPECTS = '/getProspects';
const GET_SINGLE_PROSPECT = '/getSingleProspect';
const GET_ARCHIVED_BRAND_FILTERS = '/archive';

export default {
  getProspects() {
    return Repository.get(GET_PROSPECTS);
  },
  getSingleProspect(id) {
    return Repository.get(`${GET_SINGLE_PROSPECT}/${id}`);
  },
  getArchived() {
    return Repository.get(GET_ARCHIVED_BRAND_FILTERS);
  }
}
