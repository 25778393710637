import {RepositoryFactory} from "@/repository/RepositoryFactory";
import {INVITATION_STATUSES_DICT} from "@/utils/statusesConstants";

const invitationsRepository = RepositoryFactory.get("invitations");

const state = {
  error: '',
  loader: false,
};

const actions = {
  sendInvitation: {
    async handler({commit, dispatch, rootGetters}, {id, email = ''}) {
      commit('setError', '');
      commit('setLoader', true);

      try {
        await invitationsRepository.sendInvitationEmail({id, email: email || rootGetters.singleBrandDetail.email});
        await dispatch('changeBrandStatus', {
          id,
          onSuccessMessage: `Invitation was sent to ${rootGetters.singleBrandDetail.email}.`,
          statusToSet: INVITATION_STATUSES_DICT.sent,
          fieldName: 'invitation_stage'
        }, {root: true});
      } catch (e) {
        commit('setError', e.code ? e?.message : "Something wrong, please try to send invitation later.");
      } finally {
        commit('setLoader', false);
      }
    },
    root: true
  }
};
const getters = {
  error: state => state.error,
  loader: state => state.loader
};
const mutations = {
  setError(state, payload) {
    state.error = payload;
  },
  setLoader(state, payload) {
    state.loader = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
