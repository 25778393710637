<template>
  <b-card no-body class="mb-1">
    <b-card-header header-tag="header" class="p-1" role="tab">
      <b-button block v-b-toggle.contactInfo>
        Contact Information
      </b-button>
    </b-card-header>
    <b-collapse id="contactInfo" role="tabpanel">
      <b-card-body>
        <b-row>
          <b-col md="6">
            <b-form-group>
              <label class="form-control-label">
                Brand Name *
              </label>
              <b-form-input
                type="text"
                placeholder="Your Brand Name"
                v-model="contactInfo.brand_name"
                required
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label class="form-control-label">Email *</label>
              <b-form-input
                  :readonly="$route.name === 'Edit Client'"
                  type="email"
                  v-model="contactInfo.email"
                  placeholder="Your Email Address"
                  required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group>
              <label class="form-control-label">
                First Name *
              </label>
              <b-form-input
                  type="text"
                  placeholder="Your First Name"
                  v-model="contactInfo.firstName"
                  required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label class="form-control-label">
                Last Name *
              </label>
              <b-form-input
                  type="text"
                  placeholder="Your Last Name"
                  v-model="contactInfo.lastName"
                  required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group>
              <label class="form-control-label">
                DBA *
              </label>
              <b-form-input
                  type="text"
                  placeholder="Your DBA"
                  v-model="contactInfo.dba"
                  required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>
<script>

export default {
  name: "ContactInformation",
  props: {
    initialData: Object
  },
  data() {
    return {
      contactInfo: {
        brand_name: "",
        firstName: "",
        lastName: "",
        email: "",
        dba: "",
      }
    }
  },
  watch: {
    initialData() {
      this.contactInfo = this.initialData;
    },
    contactInfo: {
      handler() {
        this.updateForm()
      },
      deep: true
    },
  },
  methods: {
    updateForm() {
      this.$emit("change", this.contactInfo);
    }
  },
};
</script>
