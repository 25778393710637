<template>
  <b-badge :variant="variant">{{status}}</b-badge>
</template>
<script>
import {
  CLIENTS_STATUSES_DICT,
  INVITATION_STATUSES_DICT,
  NOT_AVAILABLE,
  PROSPECTS_STATUSES_DICT
} from "@/utils/statusesConstants";

export default {
  name: "StatusBadge.vue",
  props: {
    status: {
      type: String,
      default: NOT_AVAILABLE,
      description: `Badge type (
        ${CLIENTS_STATUSES_DICT.active}|
        ${CLIENTS_STATUSES_DICT.archived}|
        ${INVITATION_STATUSES_DICT.sent}|
        ${INVITATION_STATUSES_DICT.expired}|
        ${INVITATION_STATUSES_DICT.accepted}|
        ${PROSPECTS_STATUSES_DICT.new}|
        ${PROSPECTS_STATUSES_DICT.processed}
      )`
    }
  },
  computed: {
    variant() {
      switch (this.status) {
        case PROSPECTS_STATUSES_DICT.processed:
        case INVITATION_STATUSES_DICT.accepted:
        case CLIENTS_STATUSES_DICT.active:
          return 'success';
        case INVITATION_STATUSES_DICT.expired:
          return 'danger';
        case INVITATION_STATUSES_DICT.sent:
          return 'warning';
        case PROSPECTS_STATUSES_DICT.new:
          return 'info';
        case PROSPECTS_STATUSES_DICT.archived:
        case CLIENTS_STATUSES_DICT.archived:
          return 'dark';
        default:
          return 'light';
      }
    }
  }
};
</script>
<style>
</style>
