import { db, firebase } from "@/firebaseInit";

const state = {};
const getters = {};
const actions = {
  async requestClose({ commit }, payload) {
    try {
      let id = payload.id;
      await db.collection("requests")
        .doc(id)
        .update({ status: "CLOSED" });
      await db.collection("notifications").add({
        is_read: false,
        message: `Your ${payload.type}   has been closed `,
        user_id: payload.added_by,
        request_id: id,
        created: firebase.firestore.FieldValue.serverTimestamp(),
      });
    } catch (error) {
      alert(error.message);
    }
  },
};
const mutations = {};
export default {
  state,
  getters,
  actions,
  mutations,
};
