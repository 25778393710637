<template>
  <div class="main-content">
    <!-- Header -->
    <div class="header background pt-4 pt-lg-5 pb-8">
      <div class="logo-box">
        <img src="/img/brand/logo.svg" alt="SecureCPG logo" class="brand-logo" />
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <slide-y-up-transition mode="out-in" origin="center top">
        <router-view></router-view>
      </slide-y-up-transition>
    </div>
  </div>
</template>
<script>
import { SlideYUpTransition } from "vue2-transitions";

export default {
  name: "auth-layout",
  components: {
    SlideYUpTransition,
  },
};
</script>
<style>
.background {
  background: #2d4059;
}
.logo-box {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.brand-logo {
  width: 8rem;
  height: auto;
}
</style>
