<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="card shadow">
        <div class="card-header border-0">
          <div class="row align-items-center">
            <div class="col">
              <h3 class="mb-0">Sign Ins</h3>
            </div>
            <b-form-input
              placeholder="Search by email"
              style="width: 250px"
              v-model="criteria"
            ></b-form-input>
          </div>
        </div>
        <!-- {{signInUsers}} -->
        <div
          v-if="signInUsers === 'idle' || signInUsers === 'loading'"
          class="d-flex py-4 align-items-center justify-content-center"
        >
          <b-spinner />
        </div>
        <div
          v-else-if="signInUsers.length === 0"
          class="d-flex py-4 align-items-center justify-content-center"
        >
          <small>No Sign In Found!!!</small>
        </div>
        <b-table
          v-else
          responsive
          :filter="criteria"
          :fields="fields"
          :filter-function="signInsFilterFunction"
          :items="signInUsers"
          :current-page="currentPage"
          :per-page="10"
          :sort-compare="myCompare"
        ></b-table>
        <b-card-footer class="d-flex justify-content-end">
          <b-pagination
            v-model="currentPage"
            :total-rows="
              criteria
                ? signInUsers.filter((row) =>
                    row.email.toLowerCase().includes(criteria.toLowerCase())
                  ).length
                : signInUsers.length
            "
            :per-page="10"
          ></b-pagination>
        </b-card-footer>
      </div>
    </div>
  </div>
</template>
<script>
const moment = require("moment");

export default {
  name: "SignIns",
  data() {
    return {
      criteria: "",
      currentPage: 1,
      fields: [
        { key: "email", label: "EMAIL", sortable: true },
        { key: "created", label: "CREATED", sortable: true },
        { key: "signed_in", label: "SIGNED IN", sortable: true },
        { key: "provider", label: "PROVIDER" },
      ],
    };
  },
  methods: {
    signInsFilterFunction(row, filter) {
      if (filter !== "") {
        if (row.email.toLowerCase().includes(filter.toLowerCase())) {
          return true;
        } else {
          return false;
        }
      }
    },
    datesSorting(a, b) {
      a = a.split("-");
      b = b.split("-");
      a =
        parseInt(a[2], 10) * 10000 + parseInt(a[1], 10) * 100 + parseInt(a[0]);
      b =
        parseInt(b[2], 10) * 10000 + parseInt(b[1], 10) * 100 + parseInt(b[0]);
      return a - b;
    },

    myCompare(itemA, itemB, key) {
      if (key === "signed_in") {
        let a = moment(itemA[key]).format("DD-MM-YYYY");
        let b = moment(itemB[key]).format("DD-MM-YYYY");
        return this.datesSorting(a, b);
      } else if (key === "created") {
        let a = moment(itemA[key]).format("DD-MM-YYYY");
        let b = moment(itemB[key]).format("DD-MM-YYYY");
        return this.datesSorting(a, b);
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.listUsers();
  },
  watch: {
    criteria() {
      this.currentPage = 1;
    },
  },
};
</script>
<style></style>
