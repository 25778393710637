import Repository from './Repository';

const GET_CLIENTS = '/getClients';
const GET_CLIENT = '/getSingleClient';

export default {
  getClients() {
    return Repository.get(GET_CLIENTS);
  },
  getSingleClient(id) {
    return Repository.get(`${GET_CLIENT}/${id}`);
  }
}
