import UserRepository from "./UserRepository";
import InvitationRepository from "@/repository/InvitationRepository";
import ProspectsRepository from "@/repository/ProspectsRepository";
import ClientsRepository from "@/repository/ClientsRepository";

const repositories = {
  user: UserRepository,
  invitations: InvitationRepository,
  prospects: ProspectsRepository,
  clients: ClientsRepository
};

export const RepositoryFactory = {
  get: (name) => repositories[name],
};
