<template>
  <div class="card shadow">
    <div class="card-header border-0">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0">
            {{ title }}
          </h3>
        </div>
        <div class="col-3">
          <div>
            <b-button
              @click="
                () => {
                  this.resetForm();
                  newUser = !newUser;
                }
              "
              >Add New Admin</b-button
            >
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="super_admins === 'idle' || super_admins === 'loading'"
      class="text-center"
    >
      <b-spinner
        style="width: 3rem; height: 3rem;"
        label="Large Spinner"
      ></b-spinner>
    </div>
    <div v-else class="table-responsive">
      <table class="table align-items-center table-flush">
        <thead>
          <tr>
            <th class="sortable" :class="sortField === 'name' ? sortDirection : ''" @click="sortByField('name')">
              Name
            </th>
            <th>Email</th>
            <th>Status</th>
            <th>Edit</th>
            <th class="text-center">Change Status</th>
          </tr>
        </thead>

        <tbody v-for="(admins, index) in sortedData" :key="index">
          <tr v-if="admins.email">
            <th>{{ admins.name }}</th>
            <td>{{ admins.email }}</td>
            <td>
              <b-badge class="badge-dot mr-4">
                <i
                  class="bgActive"
                  :class="{ bgDisabled: admins.status === `disabled` }"
                ></i>
                <span
                  class="status statusActive"
                  :class="{ statusDisabled: admins.status === `disabled` }"
                  >{{ admins.status }}</span
                >
              </b-badge>
            </td>
            <td>
              <base-button
                v-if="admins.status === `active`"
                type="info"
                size="sm"
                @click="showEditModal(admins)"
                >Edit</base-button
              >
            </td>
            <td class="text-center">
              <base-button
                v-if="admins.status === `active`"
                type="success"
                size="sm"
                @click="disableConfirmBox(admins.docId)"
                :class="getChangeStatusLoader ? `disableLoading` : ``"
              >
                <span>Disable</span></base-button
              >
              <base-button
                v-else
                type="danger"
                size="sm"
                @click="activeConfirmBox(admins.docId)"
                :class="getChangeStatusLoader ? `disableLoading` : ``"
              >
                <span>Active</span></base-button
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- modal -->
    <b-modal v-model="newUser" centered title="Add New Admin" hide-footer>
      <b-form @submit.prevent="onSubmit">
        <b-form-group label="Name:" label-for="name">
          <b-form-input
            id="name"
            :state="nameValidation"
            v-model="form.name"
            type="text"
            placeholder="Enter Name"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          label="Email Address:"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="form.email"
            :state="emailValidation"
            type="email"
            placeholder="Enter email"
            required
          ></b-form-input>
          <b-form-invalid-feedback :state="emailValidation">
            Email Address is not valid
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group id="input-group-2" label="Password:" label-for="input-2">
          <b-form-input
            id="input-2"
            :state="passwordValidation"
            v-model="form.password"
            placeholder="Password"
            type="password"
            required
          ></b-form-input>
          <b-form-invalid-feedback :state="passwordValidation">
            Password should be greater than 6 characters
          </b-form-invalid-feedback>
        </b-form-group>
        <div class="text-right">
          <b-button type="submit" variant="success">
            <b-spinner v-if="getAddUserLoader" class="text-center" />
            <span v-else>Submit</span></b-button
          >
          <b-button variant="danger" @click="newUser = !newUser">
            Close</b-button
          >
        </div>
      </b-form>
    </b-modal>
    <b-modal
      v-model="editUserModal"
      centered
      title="Edit User Info"
      hide-footer
    >
      <b-form @submit.prevent="editUser">
        <b-form-group label="Name:" label-for="name">
          <b-form-input
            id="name"
            v-model="form.name"
            :state="nameValidation"
            type="text"
            placeholder="Enter Name"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-1" label="Email:" label-for="input-1">
          <b-form-input
            id="input-1"
            v-model="form.email"
            :state="emailValidation"
            type="email"
            placeholder="Enter email"
            required
          ></b-form-input>
          <b-form-invalid-feedback :state="emailValidation">
            Email Address is not valid
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group id="input-group-2" label="Password:" label-for="input-2">
          <b-form-input
            id="input-2"
            v-model="form.password"
            :state="passwordValidation"
            placeholder="Password"
            type="password"
          ></b-form-input>
          <b-form-invalid-feedback :state="passwordValidation">
            Password should be greater than 6 characters
          </b-form-invalid-feedback>
        </b-form-group>

        <div class="text-right">
          <b-button type="submit" variant="success">
            <b-spinner v-if="getAddUserLoader" class="text-center" />
            <span v-else>Update</span></b-button
          >
          <b-button variant="danger" @click="editUserModal = !editUserModal">
            Close</b-button
          >
        </div>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { getAlphabeticallySortedData } from "@/utils/arrayUtils";

export default {
  name: "UsersTable",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      newUser: false,
      editUserModal: false,
      validationCheck: true,
      temp_docId: "",
      sortField: null,
      sortDirection: null,
      form: {
        email: "",
        password: "",
        name: "",
      },
    };
  },
  methods: {
    async onSubmit() {
      if (this.validationCheck) {
        let resp = await this.addAdmin(this.form);
        if (resp.success === true) {
          await this.getSuper_admin();
          this.newUser = false;
        } else {
          alert(resp.message);
        }
      }
    },
    disableConfirmBox(data) {
      let payload = {
        uid: data,
        action: "disable",
      };
      this.$bvModal
        .msgBoxConfirm("Do you really want to disable user?", {
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.changeStatus(payload);
          }
        })
        .catch((err) => {
          concosle.log(err);
        });
    },
    activeConfirmBox(data) {
      let payload = {
        uid: data,
        action: "active",
      };
      this.$bvModal
        .msgBoxConfirm("Do you want to active user?", {
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.changeStatus(payload);
          }
        })
        .catch((err) => {
          concosle.log(err);
        });
    },
    resetForm() {
      this.form = {
        email: "",
        password: "",
        name: "",
      };
    },
    showEditModal(data) {
      this.resetForm();
      this.editUserModal = true;
      let displayName = data.name;
      let email = data.email;
      let uid = data.docId;
      this.temp_docId = uid;
      this.form.name = displayName;
      this.form.email = email;
    },
    async editUser() {
      let displayName = this.form.name;
      let password = this.form.password;
      let email = this.form.email;
      let uid = this.temp_docId;
      let resp = await this.editAdmin({ uid, displayName, email, password });
      if (resp.success === true) {
        uid = "";
        this.editUserModal = false;
      }
    },
    sortByField(field) {
      this.sortField = field;
      this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
    }
  },
  mounted() {
    this.getSuper_admin();
  },
  computed: {
    ...mapGetters(["super_admins"]),
    sortedData() {
      return getAlphabeticallySortedData(this.super_admins, this.sortField, this.sortDirection);
    },
    nameValidation() {
      if (this.form.name === "") {
        this.validationCheck = false;
        return null;
      }
      let nameRegex = /^([a-zA-Z ]){1,30}$/;
      if (this.form.name.match(nameRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    passwordValidation() {
      let pass = this.form.password.length;
      if (this.form.password === "") {
        this.validationCheck = false;
        return null;
      }
      if (pass > 6) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    emailValidation() {
      if (this.form.email === "") {
        this.validationCheck = false;
        return null;
      }
      if (
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.form.email)
      ) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
  },
};
</script>
<style>
.statusDisabled {
  color: red !important;
}
.statusActive {
  color: #2dce89;
}
.bgDisabled {
  background: red !important;
}
.bgActive {
  background: #2dce89;
}
.disableLoading {
  opacity: 0.4 !important;
  color: gray !important;
}
</style>
