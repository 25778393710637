<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <div class="card shadow mb-4">
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0">{{ $route.name }}</h3>
                </div>
              </div>
            </div>
            <b-card-body>
              <div v-if="globalLoader" class="loader text-center">
                <b-spinner
                    style="width: 3rem; height: 3rem"
                    label="Large Spinner"
                ></b-spinner>
              </div>
              <b-form v-else @change="onFormChanged" ref="form" @submit.prevent="handleSubmit">
                <ContactInformation :initial-data="formData" @change="setContactInfo"></ContactInformation>
                <b-card v-if="$route.name === 'Edit Client' && isShortSurveyClient" no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.brandMoreInfo>
                      Brand More Information
                    </b-button>
                  </b-card-header>
                  <b-collapse id="brandMoreInfo" role="tabpanel">
                    <b-card-body>
                      <b-row>
                        <b-col md="6">
                          <b-form-group>
                            <label class="form-control-label">
                              Your Product Category
                            </label>
                            <b-select
                              type="select"
                              v-model="formData.product_category"
                              :options="productCategories"
                            ></b-select>
                          </b-form-group>
                        </b-col>
                        <b-col
                          v-if="formData.product_category === 'Other'"
                          md="6"
                        >
                          <b-form-group>
                            <label class="form-control-label">
                              Product Category Description *
                            </label>
                            <b-form-textarea
                              v-model="formData.product_category_description"
                              no-resize
                              rows="3"
                              placeholder="Describe your product category"
                              required
                            ></b-form-textarea>
                          </b-form-group>
                        </b-col>
                        <b-col md="12">
                          <b-form-group>
                            <label class="form-control-label">
                              Projected Annual Revenue *
                            </label>
                            <b-form-radio-group required v-model="formData.annual_revenue">
                              <b-form-radio value="0-1">
                                $ 0-1 M
                              </b-form-radio>
                              <b-form-radio value="1-5">
                                $ 1-5 M
                              </b-form-radio>
                              <b-form-radio value="5-15">
                                $ 5-15 M
                              </b-form-radio>
                              <b-form-radio value="15+">
                                $ 15M +
                              </b-form-radio>
                            </b-form-radio-group>
                          </b-form-group>
                        </b-col>
                        <b-col md="6">
                          <b-form-group>
                            <label class="form-control-label">
                              Ownership Structure
                            </label>
                            <b-select
                              type="select"
                              v-model="formData.ownership_structure"
                              :options="ownershipStructure"
                            ></b-select>
                          </b-form-group>
                        </b-col>
                        <b-col
                          v-if="
                            formData.ownership_structure ===
                              'Venture Capital/Private Equity Investment'
                          "
                          md="6"
                        >
                          <b-form-group>
                            <label class="form-control-label">
                              Ownership Structure Description *
                            </label>
                            <b-form-textarea
                              v-model="formData.ownership_structure_description"
                              no-resize
                              rows="3"
                              placeholder="Describe your ownership structure"
                              required
                            ></b-form-textarea>
                          </b-form-group>
                        </b-col>
                        <b-col md="6">
                          <b-form-group>
                            <label class="form-control-label">
                              State of Primary Operation *
                            </label>
                            <b-select
                              type="select"
                              v-model="formData.state_of_operation"
                              :options="stateOfPrimaryOperation"
                              required
                            ></b-select>
                          </b-form-group>
                        </b-col>
                        <b-col md="6">
                          <b-form-group>
                            <label class="form-control-label">
                              How many States do you sell products in?
                            </label>
                            <b-select
                              type="select"
                              v-model="formData.how_many_states"
                              :options="howManyStatesYouSell"
                            ></b-select>
                          </b-form-group>
                        </b-col>
                        <b-col md="6">
                          <label class="form-control-label">
                            How many employees do you have? *
                          </label>
                          <b-form-input
                            type="text"
                            placeholder="Write number of employee"
                            v-model="formData.how_many_employees"
                            required
                          >
                          </b-form-input>
                        </b-col>
                        <b-col md="12">
                          <b-form-group>
                            <label class="form-control-label">
                              Do you sell your products online
                              Direct-to-Consumer? *
                            </label>
                            <b-form-radio-group
                                name="sell_online_options"
                                v-model="formData.sell_online"
                                required
                            >
                              <b-form-radio value="yes">Yes</b-form-radio>
                              <b-form-radio value="no">No</b-form-radio>
                            </b-form-radio-group>
                          </b-form-group>
                        </b-col>
                        <b-col md="12">
                          <b-form-group>
                            <label class="form-control-label">
                              How do you produce your product? *
                            </label>
                            <b-form-radio-group
                                name="own_manufacturing_options"
                                v-model="formData.own_manufacturing"
                                required
                            >
                              <b-form-radio value="Co-Manufacture">Co-Manufacture</b-form-radio>
                              <b-form-radio value="Self-Manufacture">Self-Manufacture</b-form-radio>
                              <b-form-radio value="combination">Combination</b-form-radio>
                            </b-form-radio-group>
                          </b-form-group>
                        </b-col>
                        <b-col md="12">
                          <b-form-group>
                            <label class="form-control-label">
                              Please briefly describe your fulfillment *
                            </label>
                            <b-form-textarea
                              v-model="formData.fulfillment_description"
                              placeholder="Describe your fulfillment"
                              rows="5"
                              no-resize
                              required
                            ></b-form-textarea>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <BrandInformationExtended
                    :initial-data="formData"
                    :show-duplicated="$route.name === 'Add Prospects' || ($route.name === 'Edit Client' && !isShortSurveyClient)"
                    @change="setBrandInfo"
                ></BrandInformationExtended>
                <ExistingPolicies :initial-data="formData.policies" @change="setPolicies"></ExistingPolicies>
                <div class="d-flex justify-content-end mt-3">
                  <b-button type="submit" class="submit-btn" :disabled="isFormPristine" variant="dark">
                    <b-spinner small v-if="loader"></b-spinner>
                    <span v-else>Save</span>
                  </b-button>
                </div>
              </b-form>
            </b-card-body>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseInput from "@/components/BaseInput.vue";
import {INVITATION_STATUSES_DICT, PROSPECTS_STATUSES_DICT} from "@/utils/statusesConstants";
import {mapActions, mapGetters} from "vuex";
import ContactInformation from "@/views/WriteForm/ContactInformation.vue";
import ExistingPolicies from "@/views/WriteForm/ExistingPolicies.vue";
import BrandInformationExtended from "@/views/WriteForm/BrandInformationExtended.vue";
export default {
  components: {BrandInformationExtended, ExistingPolicies, ContactInformation, BaseInput },
  data() {
    return {
      isFormPristine: true,
      isShortSurveyClient: false,
      interestedIn: [
        "Review Existing Coverage",
        "New Insurance Coverage",
        "Partnership",
        "Risk Mitigation Strategy",
      ],
      hearAboutUs: ["Google", "Social Media", "Collogue", "Family/Friend"],
      productCategories: [
        "Food",
        "Beverage",
        "Alcohol",
        "Household",
        "Hard Goods",
        "Cannabis",
        "Pet",
        "Baby",
        "Other",
      ],
      annualRevenue: ["$0 to $1M", "$1M to $5M", "$5M to $15M", "$15M+"],
      ownershipStructure: [
        "Self-Funded and Owned",
        "Family and Friends Owned",
        "Venture Capital/Private Equity Investment",
      ],
      stateOfPrimaryOperation: [
        "Alabama",
        "Alaska",
        "Arizona",
        "Arkansas",
        "California",
        "Colorado",
        "Connecticut",
        "Delaware",
        "Florida",
        "Georgia",
        "Hawaii",
        "Idaho",
        "Illinois",
        "Indiana",
        "Iowa",
        "Kansas",
        "Kentucky",
        "Louisiana",
        "Maine",
        "Maryland",
        "Massachusetts",
        "Michigan",
        "Minnesota",
        "Mississippi",
        "Missouri",
        "Montana",
        "Nebraska",
        "Nevada",
        "New Hampshire",
        "New Jersey",
        "New Mexico",
        "New York",
        "North Carolina",
        "North Dakota",
        "Ohio",
        "Oklahoma",
        "Oregon",
        "Pennsylvania",
        "Rhode Island",
        "South Carolina",
        "South Dakota",
        "Tennessee",
        "Texas",
        "Utah",
        "Vermont",
        "Virginia",
        "Washington",
        "West Virginia",
        "Wisconsin",
        "Wyoming",
      ],
      howManyStatesYouSell: [
        "1-5",
        "6-15",
        "16-30",
        "Nationally",
        "Internationally",
      ],
      formData: {
        brand_name: "",
        name: "",
        email: "",
        product_category: null,
        product_category_description: "",
        annual_revenue: null,
        ownership_structure: null,
        ownership_structure_description: "",
        state_of_operation: null,
        how_many_states: null,
        how_many_employees: null,
        own_manufacturing: null,
        sell_online: null,
        fulfillment_description: null,
        policies: {
          selectedFile: null,
          files: [],
          selected: [],
        },
        status: PROSPECTS_STATUSES_DICT.new,
        invitation_stage: INVITATION_STATUSES_DICT.notAvailable,
      },
      brandInfo: {},
      deletedFacilityIds: []
    };
  },
  computed: mapGetters(['globalLoader', 'error', 'loader']),
  methods: {
    ...mapActions(['getSingleClientDetails']),
    showToast(variant = null, message = '', title = '') {
      this.$bvToast.toast(message, {
        title,
        variant,
        solid: true
      })
    },
    setContactInfo(contactInfo) {
      for (const [key, prop] of Object.entries(contactInfo)) {
        this.formData[key] = prop;
      }
    },
    setBrandInfo(event) {
      this.brandInfo = event.data;
      this.deletedFacilityIds = event.deletedFacilityIds;
      if (event.facilityChanged) {
        this.onFormChanged();
      }
    },
    setPolicies(policiesInfo) {
      this.formData.policies = policiesInfo;
    },
    onFormChanged() {
      this.isFormPristine = false;
    },
    handleSubmit() {
      if (this.$route.name === "Edit Client") {
        // Update Client
        let { id, survey_id, ...rest } = this.formData;
        this.updateClient({
          id,
          survey_id,
          data: rest,
          deletedFacilityIds: this.deletedFacilityIds,
          onSuccessRequestEnd: async () => {
            await this.$router.push({
              name: "Client Detail",
              params: { id },
            });
          }
        });
      } else if (this.$route.name === "Add Prospects") {
        // Add Prospects
        this.addProspects({
          data: {
            ...this.formData,
            ...this.brandInfo
          },
          onSuccessRequestEnd: ({id}) => {
            this.$router.push({ name: "Brand Detail", params: { id }});
          },
        });
      }
    },
  },
  async created() {
    const { params: { id }, name } = this.$route;

    if (id && name === "Edit Client") {
      await this.getSingleClientDetails(id);
    }

    if (this.selectedBrandFilter) {
      let brandFilter = JSON.parse(JSON.stringify(this.selectedBrandFilter));
      if (this.selectedBrandFilter.survey) {
        brandFilter = {
          ...brandFilter,
          ...brandFilter.survey
        };
      }
      this.formData = brandFilter;
      this.brandInfo = brandFilter;
      this.isShortSurveyClient = !!this.selectedBrandFilter.annual_revenue;

      if (!this.formData.policies) {
        this.formData.policies = {
          selected: [],
          files: []
        }
      }
    }
  },
  watch: {
    error(newErrorState) {
      if (!newErrorState) return;

      this.showToast('danger', newErrorState, 'An error occurred.');
    }
  }
};
</script>

<style>
.submit-btn:disabled {
  cursor: not-allowed;
}
.loader {
  min-height: 102px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}
</style>
