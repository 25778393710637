export const processSingleEntityResponse = async (apiResponse, fileStorage) => {
  let processedResponse = null;

  if (apiResponse.data.policies) {
    const {selected, files} = apiResponse.data.policies;
    const processedFilesRequests = await Promise.allSettled(
      files.map(
        async file => {
          return {
            name: file,
            url: await fileStorage.ref(`policies/${file}`).getDownloadURL()
          };
        }
      )
    );

    processedResponse = {
      ...apiResponse.data,
      policies: {
        selected,
        files: processedFilesRequests.map(({value: {name, url}}) => {
          return {
            name: name.split('_').slice(1).join('_'),
            url
          };
        })
      }
    }
  }

  return processedResponse || apiResponse.data;
}
