<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <div class="card shadow">
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0">Clients</h3>
                </div>
                <div class="col-3">
                  <b-form-select :value="selectedFilter" @change="onFilterSelect">
                    <b-form-select-option value="ALL">All</b-form-select-option>
                    <b-form-select-option-group v-for="group of options" :key="group.id" :label="group.name">
                      <b-form-select-option v-for="item of group.items" :value="item.value">{{item.text}}</b-form-select-option>
                    </b-form-select-option-group>
                  </b-form-select>
                </div>
                <div class="col-3">
                  <b-button
                    @click="
                      () => {
                        setSelectedBrandFilter(null);
                        $router.push('/client/add');
                      }
                    "
                    variant="dark"
                    block
                    >Add Client</b-button
                  >
                </div>
              </div>
            </div>
            <div v-if="loader" class="loader text-center">
              <b-spinner
                style="width: 3rem; height: 3rem"
                label="Large Spinner"
              ></b-spinner>
            </div>
            <div v-else class="table-responsive">
              <table class="table align-items-center table-flush">
                <thead>
                  <tr>
                    <th class="sortable" :class="sortField === 'brand_name' ? sortDirection : ''" @click="sortByField('brand_name')">
                      Brand Name
                    </th>
                    <th class="sortable" :class="sortField === 'name' ? sortDirection : ''" @click="sortByField('name')">
                      Individual Name
                    </th>
                    <th>Invitation Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-if="!filteredAndSortedClients.length">
                    <td v-if="selectedFilter !== 'ALL'" colspan="6" align="center">
                      <b>No data available for the specified filters</b>
                    </td>
                    <td v-else colspan="6" align="center">
                      <b>No data available</b>
                    </td>
                  </tr>
                  <tr v-else v-for="client in filteredAndSortedClients" :key="client.id">
                    <th>{{ client.brand_name }}</th>
                    <td>{{ client.name }}</td>
                    <td>
                      <status-badge :status="client.invitation_stage" />
                    </td>
                    <td>
                      <base-button
                        type="dark"
                        size="sm"
                        @click.prevent="() => $router.push(`/clients/${client.id}/edit`)"
                        >Edit</base-button
                      >
                      <base-button
                        type="primary"
                        size="sm"
                        @click.prevent="expand(client.id)"
                        >View</base-button
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import StatusBadge from "@/components/StatusBadge.vue";
import { CLIENTS_STATUSES_DICT, INVITATION_STATUSES_DICT } from "@/utils/statusesConstants";
import { getAlphabeticallySortedData } from "@/utils/arrayUtils";

export default {
  name: "BrandInformation",
  components: {
    StatusBadge
  },
  data() {
    return {
      id: null,
      selectedFilter: "ALL",
      sortField: null,
      sortDirection: null,
      options: [
        {
          name: 'Invitation Status',
          id: 2,
          items: [
            { value: {2: INVITATION_STATUSES_DICT.sent}, text: "SENT" },
            { value: {2: INVITATION_STATUSES_DICT.accepted}, text: "ACCEPTED" },
            { value: {2: INVITATION_STATUSES_DICT.expired}, text: "EXPIRED" },
          ]
        },
      ],
    };
  },
  methods: {
    ...mapActions(["fetchClients"]),
    expand(id) {
      this.$router.push("/clients/" + id);
    },
    onFilterSelect(value) {
      this.selectedFilter = value;
    },
    sortByField(field) {
      this.sortField = field;
      this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
    }
  },
  computed: {
    ...mapGetters(["loader", "clients"]),
    filteredAndSortedClients() {
      if (this.selectedFilter === 'ALL') {
        return this.sortedClients;
      }
      const groupId = Object.keys(this.selectedFilter)[0];

      return this.sortedClients.filter(
          (request) => {
            return groupId === '1' ?
                request.status === this.selectedFilter[groupId] :
                request.invitation_stage === this.selectedFilter[groupId];
          }
      );
    },
    sortedClients() {
      return getAlphabeticallySortedData(this.clients, this.sortField, this.sortDirection);
    },
  },
  created() {
    this.fetchClients();
  },
};
</script>

<style scoped>
.loader {
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}
</style>
