<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <div class="card shadow mb-4">
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0">{{ $route.name }}</h3>
                </div>
              </div>
            </div>
            <b-card-body>
              <b-form @submit.prevent="handleSubmit">
                <ContactInformation :initial-data="formData" @change="setContactInfo"></ContactInformation>
                <BrandInformationExtended
                    :initial-data="formData"
                    :show-duplicated="$route.name === 'Add Client'"
                    @change="setBrandInfo"
                ></BrandInformationExtended>
                <ExistingPolicies :initial-data="formData.policies" @change="setPolicies"></ExistingPolicies>
                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.assignPolicy>
                      Assign Policy
                    </b-button>
                  </b-card-header>
                  <b-collapse id="assignPolicy" role="tabpanel">
                    <b-card-body>
                      <div class="row">
                        <div class="col-sm-12 col-lg-6">
                          <b-form-group
                            id="input-group-2"
                            label="Policy Title:"
                            label-for="title"
                          >
                            <b-form-input
                              id="title"
                              v-model="assigned_policy.title"
                              placeholder="Policy Title/Name"
                              type="text"
                              required
                            ></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-sm-12 col-lg-6">
                          <b-form-group
                            id="input-group-2"
                            label="Expiration Date"
                            label-for="Date"
                          >
                            <b-form-input
                              id="Date"
                              v-model="assigned_policy.expiration"
                              placeholder="Policy Title/Name"
                              type="date"
                              required
                            ></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-sm-12 col-lg-6">
                          <b-form-group
                            id="input-group-2"
                            label="Coverage Limits:"
                            label-for="coverage_limit"
                          >
                            <b-form-input
                              id="coverage_limit"
                              v-model="assigned_policy.coverage_limit"
                              placeholder="Coverage Limit"
                              type="text"
                              required
                            ></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-sm-12 col-lg-6">
                          <b-form-group
                            id="input-group-2"
                            label="Premium"
                            label-for="premium"
                          >
                            <b-form-input
                              id="premium"
                              v-model="assigned_policy.premium"
                              placeholder="Premium"
                              type="text"
                              required
                            ></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-sm-12 col-lg-6">
                          <b-form-group
                            id="input-group-2"
                            label="Insurer:"
                            label-for="insurer"
                          >
                            <b-form-input
                              id="insurer"
                              v-model="assigned_policy.insurer"
                              placeholder="Insurer"
                              type="text"
                              required
                            ></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-sm-12 col-lg-6">
                          <b-form-group
                            id="input-group-2"
                            label="Policy Number"
                            label-for="number"
                          >
                            <b-form-input
                              id="number"
                              v-model="assigned_policy.number"
                              placeholder="Policy Number"
                              type="text"
                              required
                            ></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-sm-12 col-lg-6">
                          <!-- fileUpload -->
                          <b-form-group
                              label="Assigned File"
                              label-for="assignedFile"
                          >
                            <b-form-file
                              id="assignedFile"
                              v-model="AassignedFile"
                              placeholder="Choose a file or drop it here..."
                              drop-placeholder="Drop file here..."
                              required
                            ></b-form-file>
                            <div class="mt-3" v-if="AassignedFile">
                              Selected file:
                              {{ AassignedFile ? AassignedFile.name : "" }}
                            </div>
                          </b-form-group>
                        </div>
                        <div class="col-sm-12 col-lg-6">
                          <b-form-group
                              id="input-group-2"
                              label="Deductible"
                              label-for="deductible"
                          >
                            <b-form-input
                                id="deductible"
                                v-model="assigned_policy.deductible"
                                placeholder="Deductible"
                                type="number"
                                min="0"
                                max="1000000"
                            ></b-form-input>
                          </b-form-group>
                        </div>
                      </div>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <div class="d-flex align-items-center justify-content-between flex-row-reverse mt-3">
                  <b-button type="submit" class="ml-3 mr-0" variant="dark">
                    <b-spinner small v-if="loader || getLoader"></b-spinner>
                    <span v-else>Save</span>
                  </b-button>
                  <small class="form-text text-muted">
                    After client creation an email invitation will be sent automatically to the specified address{{ formData.email ? ':' : '' }}
                    <b>{{ formData.email }}</b>
                  </small>
                </div>
              </b-form>
            </b-card-body>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseInput from "@/components/BaseInput.vue";
import ContactInformation from "@/views/WriteForm/ContactInformation.vue";
import {INVITATION_STATUSES_DICT, PROSPECTS_STATUSES_DICT} from "@/utils/statusesConstants";
import {mapGetters} from "vuex";
import ExistingPolicies from "@/views/WriteForm/ExistingPolicies.vue";
import BrandInformationExtended from "@/views/WriteForm/BrandInformationExtended.vue";

export default {
  components: { BrandInformationExtended, ExistingPolicies, BaseInput, ContactInformation },
  data() {
    return {
      formData: {
        // Contact Information
        brand_name: "",
        name: "",
        email: "",

        policies: {
          selectedFile: null,
          files: [],
          selected: [],
        },
        invitation_stage: INVITATION_STATUSES_DICT.notAvailable,
        status: PROSPECTS_STATUSES_DICT.processed,
      },
      brandInfo: {},
      AassignedFile: null,
      assigned_policy: {
        title: "",
        expiration: "",
        coverage_limit: "",
        premium: "",
        insurer: "",
        number: "",
        deductible: null
      },
    };
  },
  methods: {
    showToast(variant = null, message = '', title = '') {
      this.$bvToast.toast(message, {
        title,
        variant,
        solid: true
      })
    },
    setContactInfo(contactInfo) {
      for (const [key, prop] of Object.entries(contactInfo)) {
        this.formData[key] = prop;
      }
    },
    setBrandInfo(brandInfo) {
      this.brandInfo = brandInfo;
    },
    setPolicies(policiesInfo) {
      this.formData.policies = policiesInfo;
    },
    async handleSubmit() {
      // Add Client & Assign policy
      await this.addProspects({
        data: {
          ...this.formData,
          ...this.brandInfo.data
        },
        isClientCreateFlow: true,
        onSuccessRequestEnd: async ({id}) => {
          let form = this.assigned_policy;
          let file = this.AassignedFile;
          let resp = await this.postRequset({ form, id, file });
          if (resp.success === true) {
            this.AassignedFile = null;
          }
          await this.$router.push({
            name: "Client Detail",
            params: { id },
            query: { sent: 'true' }
          });
        },
      });
    },
  },
  computed: mapGetters(['error', 'loader', 'getLoader']),
  mounted() {
    console.log(this.selectedBrandFilter);
    if (this.$route.name === "Edit Client") this.$router.push("/clients");
  },
  watch: {
    error(newErrorState) {
      if (!newErrorState) return;

      this.showToast('danger', newErrorState, 'An error occurred.');
    }
  }
};
</script>
