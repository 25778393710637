<template>
  <b-card no-body class="mb-1">
    <b-card-header header-tag="header" class="p-1" role="tab">
      <b-button block v-b-toggle.existingPolicies>
        Existing policies
      </b-button>
    </b-card-header>
    <b-collapse id="existingPolicies" role="tabpanel">
      <b-card-body>
        <b-form-group>
          <label class="form-control-label">
            Select policy below:
          </label>
          <b-row>
            <b-col>
              <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-checkbox-group
                    id="checkbox-group-1"
                    v-model="policiesInfo.selected"
                    :options="options1"
                    :aria-describedby="ariaDescribedby"
                    name="flavour-1"
                    class="d-flex flex-column"
                ></b-form-checkbox-group>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-checkbox-group
                    id="checkbox-group-2"
                    :options="options2"
                    v-model="policiesInfo.selected"
                    :aria-describedby="ariaDescribedby"
                    name="flavour-1"
                    class="d-flex flex-column"
                ></b-form-checkbox-group>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-checkbox-group
                    id="checkbox-group-3"
                    v-model="policiesInfo.selected"
                    :options="options3"
                    :aria-describedby="ariaDescribedby"
                    name="flavour-1"
                    class="d-flex flex-column"
                ></b-form-checkbox-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form-group>
        <b-card
            class="mb-4"
            v-if="policiesInfo.files.length"
        >
          <label class="form-control-label d-block">
            Added Policy {{ policiesInfo.files.length > 1 ? 'files' : 'file' }}
          </label>
          <ul class="uploaded-files-list">
            <li v-for="file of policiesInfo.files" :key="file.name">
              <a :href="file.url" target="_blank">
                <i class="ni ni-folder-17 "></i>
                {{ file.name }}
              </a>
            </li>
          </ul>
        </b-card>
        <b-form-group>
          <label class="form-control-label">
            Add pdf below:
          </label>
          <b-form-file v-model="policiesInfo.selectedFile"></b-form-file>
        </b-form-group>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>
<script>

export default {
  name: "ExistingPolicies",
  props: {
    initialData: Object
  },
  data() {
    return {
      policiesInfo: {
        selectedFile: null,
        files: [],
        selected: [],
      },
      options1: [
        { text: "Workers Comp", value: "Workers Comp" },
        { text: "General Liability", value: "General Liability" },
        { text: "Product Recall", value: "Product Recall" },
        { text: "Business Auto", value: "Business Auto" },
      ],
      options2: [
        { text: "Directors & Officers", value: "Directors & Officers" },
        { text: "Umbrella", value: "Umbrella" },
        { text: "Cyber Liability", value: "Cyber Liability" },
        { text: "Owner Buyout", value: "Owner Buyout" },
      ],
      options3: [
        { text: "Key Employee", value: "Key Employee" },
        { text: "Commercial Crime", value: "Commercial Crime" },
        {
          text: "Employment Practices Liability",
          value: "Employment Practices Liability",
        },
        { text: "Business Property", value: "Business Property" },
      ],
    }
  },
  watch: {
    initialData() {
      this.policiesInfo = this.initialData;
    },
    policiesInfo: {
      handler() {
        this.updateForm()
      },
      deep: true
    },
  },
  methods: {
    updateForm() {
      this.$emit("change", this.policiesInfo);
    }
  },
};
</script>

<style scoped>
.uploaded-files-list {
  list-style: none;
  padding: 0;
}
</style>
