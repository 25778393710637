import firebase from "firebase";

export const getLoggedInFirebaseUser = () => {
  return new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged(
      (user) => resolve(user),
      (error) => reject(error)
    );
  });
}
