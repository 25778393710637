<template>
  <div class="card shadow">
    <div class="card-header border-0">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0">
            {{ title }}
          </h3>
        </div>
        <div class="col-3">
          <div>
            <b-form-select v-model="selectedFilter" :options="filter"></b-form-select>
          </div>
        </div>
      </div>
    </div>

    <div v-if="requestLoader" class="text-center">
      <b-spinner
        style="width: 3rem; height: 3rem;"
        label="Large Spinner"
      ></b-spinner>
    </div>
    <div v-else-if="getData.length !== 0" class="table-responsive">
      <table class="table align-items-center table-flush">
        <thead>
          <tr>
            <th class="sortable" :class="sortField === 'name' ? sortDirection : ''" @click="sortByField('name')">
              Name
            </th>
            <th>Brand Name</th>
            <th>Business Address</th>
            <th>Specific COI Wording</th>
            <th>Additional Files</th>
            <th>Email</th>
            <th>Status</th>
            <th></th>
            <th></th>
          </tr>
        </thead>

        <tbody v-for="(request, index) in getData" :key="index">
          <tr>
            <td>{{ request.name }}</td>
            <td>
              <template v-if="request.business_name">{{ request.business_name }}</template>
              <template v-else>
                <status-badge />
              </template>
            </td>
            <td>
              <template v-if="request.business_address">{{ request.business_address }}</template>
              <template v-else>
                <status-badge />
              </template>
            </td>
            <td>
              <template v-if="request.specific_coi_wording">{{ request.specific_coi_wording }}</template>
              <template v-else>
                <status-badge />
              </template>
            </td>
            <td>
              <a v-if="request.pdf_file" :href="request.pdf_file" target="_blank">View File</a>
              <template v-else>
                <status-badge />
              </template>
            </td>
            <td>{{ request.email }}</td>
            <td>
              <b-badge class="badge-dot mr-4">
                <i
                  class="bgPending"
                  :class="{ bgCompleted: request.status === `CLOSED` }"
                ></i>
                <span
                  class="status statusPending"
                  :class="{ statusCompleted: request.status === `CLOSED` }"
                  >{{ request.status }}</span
                >
              </b-badge>
            </td>
            <td>
              <base-button
                @click="uploadFile(request)"
                type="success"
                size="sm"
                v-if="request.status !== `CLOSED`"
                >Close</base-button
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else class="text-center text-muted">
      <hr />
      <p class="mb-5">
        No request found!
      </p>
    </div>

    <!-- modal -->
    <b-modal
      v-model="modalShow"
      ok-variant="danger"
      size="lg"
      centered
      ok-only
      ok-title="Close"
      hide-footer
      title="Upload File"
    >
      <b-form-file
        v-model="file"
        :state="Boolean(file)"
        placeholder="Choose a file or drop it here..."
        drop-placeholder="Drop file here..."
      ></b-form-file>
      <div class="mt-3" v-if="file">
        Selected file: {{ file ? file.name : "" }}
      </div>
      <div class="text-right mt-4">
        <b-button variant="success" @click="assignFile">
          <b-spinner v-if="getLoader" class="text-center" />
          <span v-else>Submit</span></b-button
        >
        <b-button variant="danger" @click="modalShow = !modalShow">
          Close</b-button
        >
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { getAlphabeticallySortedData } from "@/utils/arrayUtils";
import StatusBadge from "@/components/StatusBadge.vue";
export default {
  name: "COIRequests",
  components: {StatusBadge},
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      docData: {},
      modalShow: false,
      file: null,
      selectedFilter: "ALL",
      sortField: null,
      sortDirection: null,
      filter: [
        { value: "ALL", text: "All" },
        { value: "PENDING", text: "PENDING" },
        { value: "CLOSED", text: "CLOSED" },
      ],
    };
  },
  methods: {
    ...mapActions(["fetchCoiRequestes", "requestClose", "postFile", "fileUrl"]),
    uploadFile(data) {
      this.docData = data;
      this.modalShow = !this.modalShow;
    },
    async assignFile() {
      let data = this.docData;
      let id = this.docData.id;
      let file = this.file;
      if (file !== null) {
        let resp = await this.postFile(this.file);
        let url = resp.url;
        await this.requestClose(data);
        await this.fileUrl({ id, url });
        this.modalShow = !this.modalShow;
        this.file = null;
        await this.fetchCoiRequestes();
      }
    },
    sortByField(field) {
      this.sortField = field;
      this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
    }
  },
  computed: {
    ...mapGetters(["coiRequset", "requestLoader", "getLoader"]),
    getData() {
      if (this.selectedFilter === `ALL`) {
        return this.sortedData;
      } else {
        return this.sortedData.filter(
          (request) => request.status === this.selectedFilter
        );
      }
    },
    sortedData() {
      return getAlphabeticallySortedData(this.coiRequset, this.sortField, this.sortDirection);
    },
  },
  mounted() {
    this.fetchCoiRequestes();
  }
};
</script>
<style scoped>
.statusPending {
  color: red !important;
}
.statusCompleted {
  color: #2dce89 !important;
}
.bgPending {
  background: red !important;
}
.bgCompleted {
  background: #2dce89 !important;
}
</style>
