import { db, storage } from "@/firebaseInit";
import { v4 as uuidv4 } from "uuid";

const state = { getLoader: false };
const getters = {
  getLoader: (state) => state.getLoader,
};
const actions = {
  // post assigned_policy/edit_policy request
  async postRequset({ commit }, payload) {
    try {
      let form = payload.form;
      let id = payload.id;
      let fileName = payload.file.name;
      commit("setGetLoader", true);

      // file upload
      const fileextension = fileName.split(".");
      let snapshot = await storage
        .ref("rodeofiles/" + uuidv4() + "." + fileextension[1].toLowerCase())
        .put(payload.file);
      // file url
      let url = await snapshot.ref.getDownloadURL();
      form.assigned_file = url;
      // assigned_policy update
      await db.collection("assigned_policies").add({ ...form, id: id });
      // status update in brand_filter
      commit("setGetLoader", false);
      return {
        success: true,
      };
    } catch (error) {
      alert(error.message);
      commit("setGetLoader", false);
      return {
        success: false,
      };
    }
  },
  // post coi-file
  async postFile({ commit }, payload) {
    commit("setGetLoader", true);
    let fileName = payload.name;
    const fileextension = fileName.split(".");
    let snapshot = await storage
      .ref("rodeofiles/" + uuidv4() + fileextension[1].toLowerCase())
      .put(payload);
    let url = await snapshot.ref.getDownloadURL();
    return {
      url: url,
    };
  },
  // update file url
  async fileUrl({ commit }, { id, url }) {
    commit("setGetLoader", true);
    await db
      .collection("requests")
      .doc(id)
      .update({
        assigned_file: url,
      });
    commit("setGetLoader", false);
  },
  // edit policy
  async editPolicy({ commit }, { id, form, file }) {
    try {
      commit("setGetLoader", true);

      // file upload
      if (file) {
        const fileName = file.name;
        const fileextension = fileName.split(".");
        let snapshot = await storage
          .ref("rodeofiles/" + uuidv4() + "." + fileextension[1].toLowerCase())
          .put(file);
        // file url
        const url = await snapshot.ref.getDownloadURL();
        form.assigned_file = url;
      }

      //  edit policy
      await db
        .collection("assigned_policies")
        .doc(id)
        .update(form);
      commit("setGetLoader", false);
      return {
        success: true,
      };
    } catch (error) {
      alert(error.message);
      commit("setGetLoader", false);
      return {
        success: false,
      };
    }
  },
};
const mutations = {
  setGetLoader(state, payload) {
    state.getLoader = payload;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
