<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8" />
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <div class="card shadow">
            <div class="card-header border-0 not-found-message-holder">
              <h1 class="title">404</h1>
              <p class="text">
                Sorry, we couldn't find this page. But don't worry, you can find plenty of other things on our
                <router-link to="/">homepage</router-link>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "page-not-found"
}
</script>

<style scoped>
.not-found-message-holder {
  height: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.title {
  font-size: 4rem;
}
</style>
