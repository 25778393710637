import { firebase } from "@/firebaseInit";
import "firebase/auth";

const storedUser = localStorage.getItem("user");

const state = {
  user: storedUser ? JSON.parse(storedUser) : null,
  loggedIn: storedUser ? true : false,
  authLoader: false,
  resetLoader: false,
};
const getters = {
  user: (state) => state.user,
  loggedIn: (state) => state.loggedIn,
  authLoader: (state) => state.authLoader,
  resetLoader: (state) => state.resetLoader,
};
const actions = {
  // Sign Up
  async register({ commit }, { email, password }) {
    try {
      await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password);

      alert("User Added Successfully");
    } catch (error) {
      alert(error.message);
    }
  },
  // Sign In
  async logIn({ commit }, { email, password }) {
    commit("setAuthLoading", true);
    try {
      const user = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password);

      firebase
        .firestore()
        .collection("super_admin")
        .doc(user.user.uid)
        .onSnapshot((doc) => {
          let tempUser = {};
          console.log("Doc", doc.data());
          if (doc.data()) {
            tempUser = { id: doc.id, ...doc.data() };
            commit("usersData", tempUser);
            commit("setAuthLoading", false);
          } else {
            alert("Invalid Admin!");
            commit("setAuthLoading", false);
          }
        });
    } catch(error) {
      alert(error.message);
      commit("setAuthLoading", false);
    }
  },
  // sign out
  async logOut({ commit }) {
    try {
      await firebase.auth().signOut();
      commit("signOut");
      return {
        logOut: true,
      };
    } catch (error) {
      alert(error.message);
    }
  },
  async resetPassword({ commit }, payload) {
    console.log(payload);
    commit("setResetLoader", true);

    try {
      await firebase
        .auth()
        .sendPasswordResetEmail(payload);

      commit("setResetLoader", false);
      alert("Reset email has been sent!");
    } catch (error) {
      commit("setResetLoader", false);
      alert(error.message);
    }
  },
};

const mutations = {
  usersData(state, payload) {
    state.user = payload;
    state.loggedIn = true;

    //localStorage
    localStorage.setItem("user", JSON.stringify(state.user));
  },
  signOut(state) {
    localStorage.clear();
    state.userData = null;
    state.loggedIn = false;
  },

  setAuthLoading(state, payload) {
    state.authLoader = payload;
  },
  setResetLoader(state, payload) {
    state.resetLoader = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
